var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{ 'empty-table-bottom': _vm.tableData.length === 0 }},[_c('DataTable',{attrs:{"className":"no-background","itemKey":"id","search":_vm.searchText,"showSelect":false,"headers":_vm.mutableTableHeaders,"items":_vm.tableData,"table":"sitedata_uploads","loading":_vm.loading,"sortBy":"upload_start","sortDesc":true,"itemsPerPage":5},scopedSlots:_vm._u([{key:"header.status",fn:function(ref){
var header = ref.header;
return [_c('a',{on:{"click":_vm.handleHelpClick}},[_c('v-icon',{staticClass:"mr-1 info-circle",attrs:{"small":""}},[_vm._v(_vm._s(_vm.informationIcon))])],1),_vm._v(" "+_vm._s(header.text)+" ")]}},{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"no-background data-table-toolbar",attrs:{"flat":"","dense":""}},[_c('div',{staticClass:"d-flex align-center"},[_c('v-toolbar-title',{staticClass:"mr-4"},[_vm._v("Uploads")]),_c('v-autocomplete',{staticClass:"mr-4 data-uploads-type-dropdown",attrs:{"value":_vm.dataUploaderType,"items":['User', 'Device'],"label":"Upload Type","hide-no-data":"","hide-selected":"","hide-details":"","dense":"","solo":""},on:{"change":function($event){return _vm.$emit('updateDataUploaderType', $event)}}}),_c('div',{staticClass:"mr-3 search-filter-wrapper"},[_c('v-text-field',{staticClass:"no-border",attrs:{"solo":"","dense":"","label":"Search...","append-icon":"mdi-magnify","hide-details":"","data-ga":"sitedata_uploads_table_search"},on:{"input":_vm.updateSearch}})],1),(_vm.hasWriteAccess)?_c('div',{attrs:{"id":"upload-dialog"}},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"h100 icon-hover",on:{"click":_vm.showUploadDialog}},'v-icon',attrs,false),on),[_vm._v("mdi-upload")])]}}],null,false,1874214793)},[_c('span',[_vm._v("Upload site data")])])],1):_vm._e()],1),_c('div',{staticClass:"ml-3 mb-3 column-filter-wrapper align-self-end"},[_c('v-tooltip',{attrs:{"top":"","open-delay":_vm.popupDelay},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"pointer",attrs:{"id":"cog-icon-uploads"}},'v-icon',attrs,false),on),[_vm._v("mdi-cog-outline")])]}}])},[_c('span',[_vm._v("Column filters")])]),_c('CustomMenuFilter',{attrs:{"menuOpen":_vm.colFilterMenuOpen,"menuActivator":"#cog-icon-uploads","menuItems":_vm.columnFilterMenuItems,"showAll":_vm.showAllCols,"offset":"y","filterType":"column","table":"site_data_uploads_table"},on:{"changeFilters":_vm.toggleColSelection,"clearSelectedItems":_vm.clearSelectedColItems,"setSearchText":_vm.setColumnFilterSearchText,"clearFilterSearch":_vm.clearColumnFilterSearch}})],1)])]},proxy:true},{key:"body",fn:function(ref){
var items = ref.items;
return [_c('tbody',_vm._l((items),function(item){return _c('tr',{key:item.id},[(_vm.checkColumn('file_name'))?_c('td',{class:{
              'word-break-filename': _vm.checkFileString(item.file_name),
            }},[_vm._v(" "+_vm._s(item.file_name)+" ")]):_vm._e(),(_vm.checkColumn('type'))?_c('td',[_vm._v(" "+_vm._s(item.type)+" ")]):_vm._e(),(_vm.checkColumn('uploaded_by'))?_c('td',[_vm._v(" "+_vm._s(item.uploaded_by)+" ")]):_vm._e(),(_vm.checkColumn('upload_start'))?_c('td',[_vm._v(" "+_vm._s(item.upload_start)+" ")]):_vm._e(),(_vm.checkColumn('last_updated'))?_c('td',[_vm._v(" "+_vm._s(item.last_updated)+" ")]):_vm._e(),(_vm.checkColumn('status'))?_c('td',[_c('div',{staticClass:"d-flex status-col"},[(item.uploading)?_c('div',{staticClass:"d-flex justify-center align-center status-col__spinner mr-2"},[_c('v-progress-circular',{attrs:{"size":20},model:{value:(_vm.percentageUploadsComplete[item.id]),callback:function ($$v) {_vm.$set(_vm.percentageUploadsComplete, item.id, $$v)},expression:"percentageUploadsComplete[item.id]"}})],1):_vm._e(),_c('div',[_c('v-tooltip',{attrs:{"top":"","disabled":item.status_reason === null,"open-delay":_vm.popupDelay,"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('span',[_vm._v(" "+_vm._s(item.status)+" ")]),_c('v-icon',_vm._g(_vm._b({staticStyle:{"transform":"scale(0.8)"},attrs:{"hidden":!item.status_reason}},'v-icon',attrs,false),on),[_vm._v(_vm._s(_vm.informationIcon))])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(item.status_reason))])])],1)])]):_vm._e(),(_vm.checkColumn('notes'))?_c('td',[_c('vue-markdown',{attrs:{"breaks":false,"anchor-attributes":_vm.anchorAttrs}},[_vm._v(_vm._s(item.notes))])],1):_vm._e()])}),0)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }