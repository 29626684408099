var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"data-table"},[(_vm.loading)?_c('div',{staticClass:"d-flex justify-center align-center data-table__spinner",attrs:{"data-cy":"di-data-loading-spinner"}},[_c('v-progress-circular',{attrs:{"size":40,"color":"primary","indeterminate":""}})],1):_vm._e(),_c('v-data-table',{staticClass:"no-background di-test-table",attrs:{"data-cy":"di-test-table","search":_vm.searchText,"items":_vm.copiedTestStatusData,"headers":_vm.mutableTableHeaders,"emptyDataText":"No data available","calculate-widths":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('v-toolbar',{staticClass:"no-background",attrs:{"flat":"","dense":""}},[_c('div',{staticClass:"d-flex align-center flex-wrap"},[_c('v-toolbar-title',{staticClass:"mr-2"},[_vm._v("Data integration status")]),_c('v-icon',{staticClass:"mr-2 pointer table-description",attrs:{"data-cy":"di-information-icon","size":"1.25rem"},on:{"click":_vm.handleHelpClick}},[_vm._v(_vm._s(_vm.informationIcon))]),_c('div',{staticClass:"mr-4 search-filter-wrapper"},[_c('v-text-field',{staticClass:"no-border",attrs:{"solo":"","dense":"","label":"Search...","append-icon":"mdi-magnify","hide-details":"","value":_vm.searchText},on:{"input":_vm.updateSearch}})],1)],1)]),_c('div',{staticClass:"column-filter-wrapper mr-4 d-flex"},[(_vm.isEdit && _vm.changesMade)?_c('v-tooltip',{attrs:{"top":"","open-delay":_vm.popupDelay},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"data-cy":"update-di-table-icon","size":"1.7rem","color":"green"},on:{"click":function($event){return _vm.updateTable($event)}}},'v-icon',attrs,false),on),[_vm._v("mdi-check")])]}}],null,false,2062227012)},[_c('span',[_vm._v("Save")])]):_vm._e(),_c('div',{staticClass:"mr-2 pointer"},[(_vm.isInternalAdmin && !_vm.isEdit)?_c('v-icon',{attrs:{"data-cy":"edit-di-table-icon"},on:{"click":function($event){_vm.isEdit = true}}},[_vm._v("mdi-pencil")]):_vm._e(),(_vm.isEdit)?_c('v-icon',{attrs:{"data-cy":"close-edit-di-table-icon","size":"1.5rem"},on:{"click":_vm.closeEditing}},[_vm._v("mdi-close")]):_vm._e()],1),_c('v-tooltip',{attrs:{"top":"","open-delay":_vm.popupDelay},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"pointer",attrs:{"id":"di-cog-icon"}},'v-icon',attrs,false),on),[_vm._v("mdi-cog-outline")])]}}])},[_c('span',[_vm._v("Column filters")])]),_c('CustomMenuFilter',{attrs:{"menuOpen":_vm.colFilterMenuOpen,"menuActivator":"#di-cog-icon","menuItems":_vm.columnFilterMenuItems,"showAll":_vm.showAllCols,"offset":"y","filterType":"column","table":"diTests"},on:{"changeFilters":_vm.toggleColSelection,"clearSelectedItems":_vm.clearSelectedColItems,"setSearchText":_vm.setColumnFilterSearchText,"clearFilterSearch":_vm.clearColumnFilterSearch}})],1)],1)]},proxy:true},{key:"body",fn:function(ref){
var items = ref.items;
return [_c('tbody',_vm._l((items),function(item,index){return _c('tr',{key:item.id,on:{"mouseover":function($event){return _vm.selectItem(item)},"mouseleave":function($event){return _vm.unselectItem(item)}}},[(_vm.checkColumn('name'))?_c('td',[_c('div',[_vm._v(_vm._s(item.name))])]):_vm._e(),(_vm.checkColumn('status'))?_c('td',[_c('div',{staticClass:"status-select"},[_c('v-select',{staticClass:"no-border",attrs:{"data-cy":"select-status-input","value":item.status ? item.status : null,"clearable":"","disabled":!_vm.isEdit,"items":item.status_options,"item-text":"name","append-icon":_vm.isEdit ? '$dropdown' : '',"dense":"","hide-details":""},on:{"change":function($event){return _vm.changeStatusOption($event, item, 'change')},"blur":function($event){return _vm.changeStatusOption($event, item, 'blur')}}})],1)]):_vm._e(),(_vm.checkColumn('notes'))?_c('td',{class:("notes-cell_" + index + " notes-markdown")},[(!_vm.isEdit)?_c('v-menu',{attrs:{"open-on-hover":"","offset-y":"","top":"","content-class":"notes-menu","nudge-top":"16px","nudge-left":"50px;","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('vue-markdown',{attrs:{"data-cy":"di-notes-markdown","breaks":false,"anchor-attributes":_vm.anchorAttrs}},[_vm._v(_vm._s(_vm.shortenNotes(item.notes)))])],1)]}}],null,true)},[_c('div',[_c('vue-markdown',{attrs:{"breaks":false,"anchor-attributes":_vm.anchorAttrs}},[_vm._v(_vm._s(item.notes))])],1)]):_vm._e(),(_vm.isEdit)?_c('div',{staticClass:"mb-2 notes-textarea"},[_c('v-textarea',{attrs:{"data-cy":"di-status-table-notes-input","dense":"","rows":"1","auto-grow":"","hide-details":"","clearable":""},on:{"change":_vm.changeNotes},model:{value:(item.notes),callback:function ($$v) {_vm.$set(item, "notes", $$v)},expression:"item.notes"}})],1):_vm._e()],1):_vm._e()])}),0)]}},{key:"footer.prepend",fn:function(){return [_c('div',{staticClass:"last-updated-text"},[(_vm.tableLastUpdated && 'updatedBy' in _vm.tableLastUpdated)?_c('p',{staticClass:"ma-0"},[_vm._v(" Last updated "+_vm._s(_vm.tableLastUpdated.updatedTs)+" by "+_vm._s(_vm.tableLastUpdated.updatedBy)+". ")]):_vm._e()])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }