import { render, staticRenderFns } from "./PriorityCard.vue?vue&type=template&id=eabc515c&scoped=true&"
import script from "./PriorityCard.vue?vue&type=script&lang=js&"
export * from "./PriorityCard.vue?vue&type=script&lang=js&"
import style0 from "./PriorityCard.vue?vue&type=style&index=0&id=eabc515c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "eabc515c",
  null
  
)

export default component.exports