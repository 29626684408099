var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"turbines-tab-container"},[_c('TableHeaderFilter',{attrs:{"headers":_vm.headers,"selectedHeaders":_vm.selectedHeaders,"menuActivator":"#site-dash-turbines-col-filter"},on:{"changeHeaders":_vm.changeSelectedHeaders}}),_c('DynamicTable',{attrs:{"headers":_vm.selectedHeaders,"items":_vm.turbines,"loading":_vm.loading,"hasFooter":true,"menuActivator":"site-dash-turbines-col-filter"},scopedSlots:_vm._u([{key:"header_openIssues",fn:function(ref){
var header = ref.header;
return [_c('div',_vm._l((header.text),function(value,index){return _c('div',{key:index},[_c('div',[_vm._v(_vm._s(value))])])}),0)]}},{key:"header_openReliabilityIssues",fn:function(ref){
var header = ref.header;
return [_c('div',_vm._l((header.text),function(value,index){return _c('div',{key:index},[_c('div',[_vm._v(_vm._s(value))])])}),0)]}},{key:"header_openPerformanceIssues",fn:function(ref){
var header = ref.header;
return [_c('div',_vm._l((header.text),function(value,index){return _c('div',{key:index},[_c('div',[_vm._v(_vm._s(value))])])}),0)]}},{key:"number",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"link-cell",attrs:{"to":{
          name: 'NewTurbineDashboard',
          params: {
            turbineId: item.id,
          },
        }}},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',{staticClass:"link-text"},[_vm._v(_vm._s(item.number))]),_c('div',{staticClass:"mr-1 pointer"},[_c('v-icon',{attrs:{"size":"1.25rem"}},[_vm._v("mdi-open-in-new")])],1)])])]}},{key:"status",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"status-item"},[_c('v-icon',{attrs:{"color":item.status}},[_vm._v("mdi-circle-medium")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }