<template>
  <div class="container">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "PDFPage",
};
</script>

<style lang="scss" scoped>
.container {
  height: 100%;
  width: 98%;
  background: rgba(6, 22, 45, 0);
  overflow: auto;
}
</style>
