<template>
  <div :class="{ 'empty-table-bottom': tableData.length === 0 }">
    <DataTable
      className="no-background"
      itemKey="id"
      :search="searchText"
      :showSelect="false"
      :headers="mutableTableHeaders"
      :items="tableData"
      table="sitedata_uploads"
      :loading="loading"
      sortBy="upload_start"
      :sortDesc="true"
      :itemsPerPage="5"
    >
      <template v-slot:header.status="{ header }">
        <a @click="handleHelpClick">
          <v-icon small class="mr-1 info-circle">{{ informationIcon }}</v-icon>
        </a>
        {{ header.text }}
      </template>
      <template v-slot:top>
        <v-toolbar flat dense class="no-background data-table-toolbar">
          <div class="d-flex align-center">
            <v-toolbar-title class="mr-4">Uploads</v-toolbar-title>
            <v-autocomplete
              :value="dataUploaderType"
              :items="['User', 'Device']"
              class="mr-4 data-uploads-type-dropdown"
              label="Upload Type"
              hide-no-data
              hide-selected
              hide-details
              dense
              solo
              @change="$emit('updateDataUploaderType', $event)"
            ></v-autocomplete>
            <div class="mr-3 search-filter-wrapper">
              <v-text-field
                solo
                dense
                label="Search..."
                append-icon="mdi-magnify"
                hide-details
                class="no-border"
                data-ga="sitedata_uploads_table_search"
                @input="updateSearch"
              ></v-text-field>
            </div>
            <div id="upload-dialog" v-if="hasWriteAccess">
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    @click="showUploadDialog"
                    class="h100 icon-hover"
                    >mdi-upload</v-icon
                  >
                </template>
                <span>Upload site data</span>
              </v-tooltip>
            </div>
          </div>
          <div class="ml-3 mb-3 column-filter-wrapper align-self-end">
            <v-tooltip top :open-delay="popupDelay">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  id="cog-icon-uploads"
                  class="pointer"
                  v-on="on"
                  v-bind="attrs"
                  >mdi-cog-outline</v-icon
                >
              </template>
              <span>Column filters</span>
            </v-tooltip>
            <CustomMenuFilter
              :menuOpen="colFilterMenuOpen"
              menuActivator="#cog-icon-uploads"
              :menuItems="columnFilterMenuItems"
              :showAll="showAllCols"
              offset="y"
              filterType="column"
              table="site_data_uploads_table"
              @changeFilters="toggleColSelection"
              @clearSelectedItems="clearSelectedColItems"
              @setSearchText="setColumnFilterSearchText"
              @clearFilterSearch="clearColumnFilterSearch"
            />
          </div>
        </v-toolbar>
      </template>
      <template v-slot:body="{ items }">
        <tbody>
          <tr v-for="item in items" :key="item.id">
            <td
              v-if="checkColumn('file_name')"
              :class="{
                'word-break-filename': checkFileString(item.file_name),
              }"
            >
              {{ item.file_name }}
            </td>
            <td v-if="checkColumn('type')">
              {{ item.type }}
            </td>
            <td v-if="checkColumn('uploaded_by')">
              {{ item.uploaded_by }}
            </td>
            <td v-if="checkColumn('upload_start')">
              {{ item.upload_start }}
            </td>
            <td v-if="checkColumn('last_updated')">
              {{ item.last_updated }}
            </td>
            <td v-if="checkColumn('status')">
              <div class="d-flex status-col">
                <div
                  class="d-flex justify-center align-center status-col__spinner mr-2"
                  v-if="item.uploading"
                >
                  <v-progress-circular
                    :size="20"
                    v-model="percentageUploadsComplete[item.id]"
                  ></v-progress-circular>
                </div>
                <div>
                  <v-tooltip
                    top
                    :disabled="item.status_reason === null"
                    :open-delay="popupDelay"
                    max-width="500px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <span>
                        {{ item.status }}
                      </span>
                      <v-icon
                        style="transform: scale(0.8)"
                        :hidden="!item.status_reason"
                        v-bind="attrs"
                        v-on="on"
                        >{{ informationIcon }}</v-icon
                      >
                    </template>
                    <span> {{ item.status_reason }}</span>
                  </v-tooltip>
                </div>
              </div>
            </td>
            <td v-if="checkColumn('notes')">
              <vue-markdown :breaks="false" :anchor-attributes="anchorAttrs">{{
                item.notes
              }}</vue-markdown>
            </td>
          </tr>
        </tbody>
      </template>
    </DataTable>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import dayjs from "dayjs";
import DataTable from "@/components/DataTable";
import VueMarkdown from "vue-markdown-v2";
import { popupDelay } from "@/helpers/variables";
import CustomMenuFilter from "./CustomMenuFilter";
import { informationIcon } from "@/helpers/variables";

export default {
  name: "SiteDataUploadsTable",
  emits: ["setShowDialog"],
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    hasWriteAccess: {
      type: Boolean,
      default: false,
    },
    dataUploaderType: {
      type: String,
      default: "User",
    },
  },
  components: {
    DataTable,
    VueMarkdown,
    CustomMenuFilter,
  },
  data() {
    return {
      tableHeaders: [
        { id: 1, text: "File name", value: "file_name", show: true },
        { id: 2, text: "Type", value: "type", show: true },
        { id: 3, text: "Uploaded by", value: "uploaded_by", show: true },
        { id: 4, text: "Uploaded", value: "upload_start", show: true },
        { id: 5, text: "Last updated", value: "last_updated", show: true },
        { id: 6, text: "Status", value: "status", show: true },
        { id: 7, text: "Notes", value: "notes", show: true },
      ],
      itemsPerPage: 10,
      searchText: "",
      /**
       * Holds the attributes to be added to the `vue-markdown` link
       */
      anchorAttrs: {
        target: "_blank",
        rel: "noopener noreferrer nofollow",
      },
      popupDelay,
      showAll: true,
      tableRows: [],
      colFilterMenuOpen: false,
      selectableTableHeaders: [],
      mutableTableHeaders: [],
      searchColumnMenuText: "",
      showAllCols: false,
      informationIcon,
    };
  },
  computed: {
    ...mapState({
      siteData: (state) => state.site.siteData,
      siteRawdata: (state) => state.site.siteRawdata,
      userData: (state) => state.user.userData,
      rawdataUpdated: (state) => state.site.rawdataUpdated,
      uploadProgress: (state) => state.site.uploadProgress,
    }),
    /**
     * Map the table data to be displayed
     */
    tableData() {
      return this.tableRows;
    },
    percentageUploadsComplete() {
      return Object.keys(this.uploadProgress).reduce((acc, key) => {
        const values = Object.values(this.uploadProgress[key]);
        const trueCount = values.filter((value) => value === true).length;
        const percentage = (trueCount / values.length) * 100;
        acc[key] = percentage;
        return acc;
      }, {});
    },
    // Results of filter search if search text present
    columnFilterMenuItems() {
      if (this.searchColumnMenuText) {
        return this.selectableTableHeaders?.filter((header) =>
          header.text
            .toLowerCase()
            .includes(this.searchColumnMenuText?.toLowerCase()),
        );
      } else {
        return this.selectableTableHeaders;
      }
    },
  },
  methods: {
    ...mapActions({
      setRawdataUpdated: "site/isRawdataUpdated",
      updateShowBurger: "app/updateShowBurger",
      updateBurgerData: "app/updateBurgerData",
    }),
    /**
     * Update subscription data for flyout display
     *
     * Opens the flyout containing subscription information
     */
    handleHelpClick() {
      this.updateShowBurger(true);
      this.updateBurgerData({
        name: "Upload status definitions",
        description: `| Status             | Description                                                  |
| ------------------ | ------------------------------------------------------------ |
| Upload in progress | Data is uploading to the WindESCo system.                     |
| Upload failed      | Upload was started but did not finish.           |
| Processing         | Data is pending processing by the WindESCo system.            |
| Processing failed  | Data was not successfully processed into the WindESCo format. This could be due to a missing or invalid transformation configuration. |
| Processed          | Data was successfully uploaded and transformed into the WindESCo format and is ready for analytics to be run.             |

`,
      });
    },
    setTableData() {
      const siteId = +this.$route.params.siteId;
      if (
        this.siteRawdata?.length > 0 &&
        this.siteRawdata[0].site_id === siteId
      ) {
        const dataUploadsMap = this.siteRawdata.map((upload) => ({
          id: upload.id,
          file_name: upload.name,
          uploaded_by: upload.user_email,
          upload_start: dayjs(upload.upload_start_ts).format(
            "YYYY-MM-DD HH:mm:ss",
          ),
          last_updated: this.determineLastUpdated(upload),
          status: upload.status,
          status_reason: upload.status_reason,
          type: upload.kind,
          notes: upload.notes,
          uploading: upload.uploading,
        }));
        this.tableRows = dataUploadsMap;
      } else {
        this.tableRows = [];
      }
    },
    checkColumn(column) {
      return this.selectableTableHeaders?.find(
        (c) => c.value === column && c.show,
      );
    },
    checkFileString(fileString) {
      if (fileString.includes("/")) {
        return true;
      }
      return false;
    },
    toggleColSelection(item) {
      let isAMatch = false;
      const index = this.selectableTableHeaders.indexOf(item);
      if (item === "selectAll") {
        this.selectAllColumns();
      } else if (item === "reset") {
        this.clearColumnFilterSearch();
        this.selectableTableHeaders.forEach((header) => (header.show = true));
        this.mutableTableHeaders = [...this.selectableTableHeaders];
      } else {
        if (index > -1) {
          this.selectableTableHeaders[index].show =
            !this.selectableTableHeaders[index].show;

          if (this.tableHeaders.length > 0) {
            for (const header of this.tableHeaders) {
              if (
                header.id === this.selectableTableHeaders[index].id &&
                !this.selectableTableHeaders[index].show
              ) {
                this.mutableTableHeaders.splice(
                  this.mutableTableHeaders.indexOf(header),
                  1,
                );
                isAMatch = true;
                break;
              }
            }
            // Place or remove the correct column from mutable header array
            if (!isAMatch) {
              let closestIndex = -1; // Initialize index of closest ID
              let minDifference = Infinity; // Initialize minimum difference
              const idOfMissingHeader = this.selectableTableHeaders[index].id;

              if (
                this.mutableTableHeaders.length > 0 &&
                this.mutableTableHeaders[0]?.id < idOfMissingHeader
              ) {
                for (let i = 0; i < this.mutableTableHeaders.length; i++) {
                  const difference =
                    idOfMissingHeader - this.mutableTableHeaders[i].id;
                  if (difference > 0 && difference < minDifference) {
                    closestIndex = i;
                    minDifference = difference;
                  }
                }
                // Insert header just after the header with the closest smaller id
                this.mutableTableHeaders.splice(
                  closestIndex + 1,
                  0,
                  this.selectableTableHeaders[index],
                );
              } else {
                this.mutableTableHeaders.unshift(
                  this.selectableTableHeaders[index],
                );
              }
            }
          }
        }
      }
    }, // Click a point to load timeseries
    selectAllColumns() {
      this.clearColumnFilterSearch();
      this.showAllCols = true;
      for (const header of this.selectableTableHeaders) {
        if (!header.show) {
          this.showAllCols = false;
          break;
        }
      }
      if (this.showAllCols) {
        this.selectableTableHeaders.forEach((header) => (header.show = false));
        this.mutableTableHeaders = [];
      } else {
        this.selectableTableHeaders.forEach((header) => (header.show = true));
        this.mutableTableHeaders = [...this.selectableTableHeaders];
      }
    },
    clearSelectedColItems() {
      this.clearColumnFilterSearch();
      this.selectableTableHeaders.forEach((header) => (header.show = false));
      this.mutableTableHeaders = [];
    },
    clearColumnFilterSearch() {
      this.searchColumnMenuText = "";
    },
    setColumnFilterSearchText(searchText) {
      this.searchColumnMenuText = searchText;
    },
    /**
     * Filter items in the table
     */
    updateSearch(e) {
      this.searchText = e;
    },
    /**
     * Open the dialog for uploading a file
     */
    showUploadDialog() {
      this.$emit("setShowDialog", true);
    },
    determineLastUpdated(upload) {
      if (upload.process_end_ts) {
        return dayjs(upload.process_end_ts).format("YYYY-MM-DD HH:mm:ss");
      } else if (upload.process_start_ts) {
        return dayjs(upload.process_start_ts).format("YYYY-MM-DD HH:mm:ss");
      } else if (upload.upload_end_ts) {
        return dayjs(upload.upload_end_ts).format("YYYY-MM-DD HH:mm:ss");
      } else if (upload.upload_start_ts) {
        return dayjs(upload.upload_start_ts).format("YYYY-MM-DD HH:mm:ss");
      }
    },
  },
  mounted() {
    this.$emit("callForSiteDataUploads");
  },
  watch: {
    siteRawdata: {
      immediate: true,
      handler() {
        this.setTableData();
      },
    },
    rawdataUpdated: {
      immediate: true,
      handler(value) {
        if (value) {
          this.setTableData();
          this.setRawdataUpdated(false);
        }
      },
    },
    tableHeaders: {
      immediate: true,
      handler(data) {
        if (data.length > 0) {
          this.selectableTableHeaders = [...data];
          this.mutableTableHeaders = [...data];
        }
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.status-col {
  &__spinner {
    color: var(--v-gray-base);
    z-index: 1000;
  }
}
.info-icon.popup-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-filter {
  max-width: 18rem;
  width: 18rem;
}

.issue-filter {
  max-width: 9rem;
}

.columns-filter {
  max-width: 8rem;
}

#upload-dialog {
  position: relative;
  z-index: 1000;
}

.upload-spinner {
  position: absolute;
  top: 25%;
  left: 50%;
  z-index: 1070;
}

.type {
  border-radius: 4px;
  padding: 6px 8px;
  margin: 6px 0;
  display: inline-block;
}

.word-break-filename {
  white-space: normal;
  word-break: break-all;
}

.upload-container {
  justify-content: space-between;
  flex-wrap: wrap;
}

::v-deep {
  .data-table-toolbar .v-toolbar__content {
    display: flex;
    justify-content: space-between;
    .data-uploads-type-dropdown {
      width: 7rem;
    }
  }
}

.empty-table-bottom {
  margin-bottom: 5rem;
}
</style>
