var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"systems-tab-container"},[_c('TableHeaderFilter',{attrs:{"headers":_vm.headers,"selectedHeaders":_vm.selectedHeaders,"menuActivator":"#site-dash-systems-col-filter"},on:{"changeHeaders":_vm.changeSelectedHeaders}}),_c('DynamicTable',{attrs:{"headers":_vm.selectedHeaders,"items":_vm.systems,"hasFooter":true,"loading":_vm.loading,"menuActivator":"site-dash-systems-col-filter"},scopedSlots:_vm._u([{key:"header_status",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"d-flex"},_vm._l((header.text),function(value,key){return _c('div',{key:key},[(key === 'icon')?_c('v-icon',{staticClass:"ml-1 help-icon-color",attrs:{"size":"medium"}},[_vm._v(_vm._s(value))]):_c('div',[_vm._v(_vm._s(value))])],1)}),0)]}},{key:"name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"link-cell",attrs:{"to":{
          name: 'AllSiteComponents',
          params: {
            siteId: _vm.siteId,
            componentName: _vm.transformName(item.name, 'route', true),
          },
        }}},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',{staticClass:"link-text"},[_vm._v(" "+_vm._s(_vm.transformName(item.name, "display"))+" ")]),_c('div',{staticClass:"mr-1 pointer"},[_c('v-icon',{attrs:{"size":"1.25rem"}},[_vm._v("mdi-open-in-new")])],1)])])]}},{key:"status",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"status-item"},[_c('v-icon',{attrs:{"color":item.status}},[_vm._v("mdi-circle-medium")])],1)]}},{key:"ticketsInProgress",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"issues-in-progress-container"},[_c('div',{staticClass:"d-flex flex-wrap align-center"},[_vm._l((item.ticketsInProgress),function(issue,index){return _c('div',{key:issue.work_order_id},[(index < 2)?_c('div',[_c('router-link',{attrs:{"to":{
                  name: 'NewIssueDetail',
                  params: {
                    issueId: issue.turbine_issue_id,
                  },
                }}},[_c('div',{staticClass:"issue-in-progress mr-1"},[_vm._v(" "+_vm._s(issue.work_order_id)+" ")])])],1):_vm._e()])}),(item.ticketsInProgress && item.ticketsInProgress.length > 2)?_c('div',{staticClass:"show-all-issues-container"},[_c('div',{staticClass:"show-all-issues-display ml-2",on:{"click":function($event){return _vm.showAllModal(item)}}},[_vm._v(" "+_vm._s(_vm.showAllIssuesInProgress ? "Show less" : "Show all")+" ")]),(_vm.showAllIssuesInProgress && _vm.selectedItem === item)?_c('div',{staticClass:"all-issues"},[_c('div',{staticClass:"d-flex justify-space-around"},_vm._l((_vm.selectedItem.ticketsInProgress),function(issue){return _c('p',{key:issue,staticClass:"ma-0"},[_vm._v(" "+_vm._s(issue)+" ")])}),0)]):_vm._e()]):_vm._e()],2)])]}},{key:"impact_mwh",fn:function(ref){
                var item = ref.item;
return [_c('div',[_c('span',{staticClass:"mr-1"},[_vm._v(_vm._s(item.impact_mwh)+" MWh")]),_c('span',[_vm._v("/")]),_c('span',{staticClass:"ml-1"},[_vm._v("$")]),_vm._v(_vm._s(item.impact_usd)+" ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }