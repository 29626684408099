import axios from "@/helpers/axiosAPI";
import store from "@/store";

export async function getAvailableSignals(payload) {
  try {
    const siteId = payload.site_id;
    const kind = payload.kind;

    const res = await axios.get(`sites/${siteId}/available-signals/${kind}`);

    if (res.status === 200) {
      const response = res.data.map((signal) => ({
        name: signal.signal_def_name,
        id: signal.signal_def_id,
        kind: signal.kind,
        label: signal.signal_label ? signal.signal_label : null,
        expected: true,
        available: true,
        siteId: siteId,
      }));

      return response;
    } else {
      const error = {
        name: "getAvailableSignals",
        message: "Error API call",
        value: "unknown error",
      };
      store.dispatch("error/raiseError", error);
    }
  } catch (error) {
    const err = {
      name: "getAvailableSignals",
      error,
    };
    store.dispatch("error/raiseError", { err });
  }
}

export async function getSignalDefinitions(payload) {
  try {
    const kind = payload.kind.toLowerCase();
    const siteId = payload.site_id;

    const res = await axios.get(`/signal-defs/${kind}`);

    if (res.status === 200) {
      const response = res.data.map((signalDef) => ({
        name: signalDef.name,
        id: signalDef.id,
        description: signalDef.description,
        kind: kind,
        siteId: siteId,
        label: signalDef.label ? signalDef.label : null,
      }));

      return response;
    } else {
      const error = {
        name: "getSignalDefinitions",
        message: "Error API call",
        value: "unknown error",
      };
      store.dispatch("error/raiseError", error);
    }
  } catch (error) {
    const err = {
      name: "getSignalDefinitions",
      error,
    };
    store.dispatch("error/raiseError", err);
  }
}

export async function updateAvailableSignals(payload) {
  try {
    const siteId = payload.site_id;
    const kind = payload.kind;
    let signals = [];
    if (Array.isArray(payload.signals)) {
      for (const signal of payload.signals) {
        let signalObj = {
          signal_def_id: signal.id,
          kind: signal.kind,
          expected: true,
          available: true,
        };
        signals.push(signalObj);
      }
    }
    const body = signals;

    const res = await axios.put(
      `/sites/${siteId}/available-signals/${kind}`,
      body,
    );

    if (res.status === 200) {
      const response = res.data.map((signal) => ({
        name: signal.signal_def_name,
        id: signal.signal_def_id,
        kind: signal.kind,
        label: signal.signal_label ? signal.signal_label : null,
        expected: true,
        available: true,
        siteId: siteId,
      }));

      return response;
    } else {
      const error = {
        name: "updateAvailableSignals",
        message: "Error API call",
        value: "unknown error",
      };
      store.dispatch("error/raiseError", error);
    }
  } catch (error) {
    const err = {
      name: "updateAvailableSignals",
      error,
    };
    store.dispatch("error/raiseError", { err });
  }
}
