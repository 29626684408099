<template>
  <div class="systems-tab-container">
    <TableHeaderFilter
      :headers="headers"
      :selectedHeaders="selectedHeaders"
      menuActivator="#site-dash-systems-col-filter"
      @changeHeaders="changeSelectedHeaders"
    />
    <DynamicTable
      :headers="selectedHeaders"
      :items="systems"
      :hasFooter="true"
      :loading="loading"
      menuActivator="site-dash-systems-col-filter"
    >
      <template v-slot:header_status="{ header }">
        <div class="d-flex">
          <div v-for="(value, key) in header.text" :key="key">
            <v-icon
              v-if="key === 'icon'"
              size="medium"
              class="ml-1 help-icon-color"
              >{{ value }}</v-icon
            >
            <div v-else>{{ value }}</div>
          </div>
        </div>
      </template>
      <template v-slot:name="{ item }">
        <router-link
          :to="{
            name: 'AllSiteComponents',
            params: {
              siteId: siteId,
              componentName: transformName(item.name, 'route', true),
            },
          }"
          class="link-cell"
        >
          <div class="d-flex justify-space-between">
            <div class="link-text">
              {{ transformName(item.name, "display") }}
            </div>
            <div class="mr-1 pointer">
              <v-icon size="1.25rem">mdi-open-in-new</v-icon>
            </div>
          </div>
        </router-link>
      </template>
      <template v-slot:status="{ item }">
        <div class="status-item">
          <v-icon :color="item.status">mdi-circle-medium</v-icon>
        </div>
      </template>
      <template v-slot:ticketsInProgress="{ item }">
        <div class="issues-in-progress-container">
          <div class="d-flex flex-wrap align-center">
            <div
              v-for="(issue, index) in item.ticketsInProgress"
              :key="issue.work_order_id"
            >
              <div v-if="index < 2">
                <router-link
                  :to="{
                    name: 'NewIssueDetail',
                    params: {
                      issueId: issue.turbine_issue_id,
                    },
                  }"
                >
                  <div class="issue-in-progress mr-1">
                    {{ issue.work_order_id }}
                  </div></router-link
                >
              </div>
            </div>
            <div
              class="show-all-issues-container"
              v-if="item.ticketsInProgress && item.ticketsInProgress.length > 2"
            >
              <div
                @click="showAllModal(item)"
                class="show-all-issues-display ml-2"
              >
                {{ showAllIssuesInProgress ? "Show less" : "Show all" }}
              </div>
              <div
                v-if="showAllIssuesInProgress && selectedItem === item"
                class="all-issues"
              >
                <div class="d-flex justify-space-around">
                  <p
                    class="ma-0"
                    v-for="issue in selectedItem.ticketsInProgress"
                    :key="issue"
                  >
                    {{ issue }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:impact_mwh="{ item }">
        <div>
          <span class="mr-1">{{ item.impact_mwh }} MWh</span><span>/</span
          ><span class="ml-1">$</span>{{ item.impact_usd }}
        </div>
      </template>
    </DynamicTable>
  </div>
</template>

<script>
import { informationIcon } from "@/helpers/variables";
import TableHeaderFilter from "@/components/TableHeaderFilter.vue";
import DynamicTable from "@/components/DynamicTable.vue";
import { transformName } from "@/helpers/functions";
import debounce from "lodash/debounce";

export default {
  name: "SiteDashboardSystemsTab",
  components: {
    TableHeaderFilter,
    DynamicTable,
  },
  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    systems: {
      type: Array,
      required: false,
      default: () => [],
    },
    siteId: {
      type: Number,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      currentPage: 1,
      rowsPerPage: 5,
      informationIcon,
      showAllIssuesInProgress: false,
      headers: [
        {
          id: 1,
          text: "NAME",
          value: "name",
        },
        {
          id: 2,
          text: {
            status: "STATUS",
            icon: informationIcon,
          },
          value: "status",
        },
        {
          id: 3,
          text: "OPEN ISSUES",
          value: "openIssues",
        },
        {
          id: 4,
          text: "TICKETS IN PROGRESS",
          value: "ticketsInProgress",
          not_sortable: true,
        },
        {
          id: 5,
          text: "IMPACT (MWh/$)",
          value: "impact_mwh",
        },
        {
          id: 6,
          text: "CLOSED ISSUES",
          value: "issuesClosed",
        },
        {
          id: 7,
          text: "LAST UPDATED",
          value: "lastUpdated",
        },
      ],
      selectedColumns: [],
      maxColumns: 7,
    };
  },
  computed: {
    systemsData() {
      if (this.systems.length > 0) {
        return this.systems;
      }
      return null;
    },
    selectedHeaders() {
      let result = this.headers;
      if (this.selectedColumns?.length > 0) {
        result = result.filter((item) => {
          const matchedHeader = this.selectedColumns.filter((column) => {
            return column.id === item.id;
          });
          if (matchedHeader?.length > 0) {
            return item;
          }
        });
        return result;
      }
      return [];
    },
  },
  methods: {
    showAllModal(item) {
      this.selectedItem = item;
      this.showAllIssuesInProgress = !this.showAllIssuesInProgress;
    },
    transformName,
    changeSelectedHeaders(header) {
      if (header === "selectAll") {
        if (
          this.selectedColumns.length === this.headers.length ||
          this.selectedColumns.length === this.maxColumns
        ) {
          this.selectedColumns = [];
          return;
        } else {
          this.selectedColumns = this.headers;
        }
      } else if (header === "reset") {
        this.selectedColumns = this.headers;
      } else if (header === "clear") {
        this.selectedColumns = [];
      } else {
        const index = this.selectedColumns.indexOf(header);
        if (index !== -1) {
          this.selectedColumns = this.selectedColumns.filter(
            (item) => item !== header,
          );
        } else {
          if (this.selectedColumns.length >= this.maxColumns) {
            this.selectedColumns.shift();
          }
          this.selectedColumns.push(header);
        }
      }
      this.ensureMaxColumns();
    },
    handleResize() {
      this.ensureMaxColumns();
    },
    ensureMaxColumns() {
      const width = window.innerWidth;
      this.maxColumns = width < 900 ? 4 : this.headers.length;
      if (this.selectedColumns.length > this.maxColumns) {
        this.selectedColumns = this.selectedColumns.slice(0, this.maxColumns);
      }
    },
  },
  mounted() {
    this.selectedColumns = this.headers;
    this.handleResize = debounce(this.handleResize, 200);
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style lang="scss" scoped>
.systems-tab-container {
  position: relative;
}

.status-green {
  color: var(--v-stalenessGreen-base);
}
.status-yellow {
  color: var(--v-stalenessYellow-base);
}
.status-red {
  color: var(--v-error-base);
}

.help-icon-color {
  color: var(--v-text-base);
  margin-bottom: 2px;
}
</style>
