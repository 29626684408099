var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex flex-wrap align-center justify-space-between"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"mr-3 mb-3 search-filter-wrapper"},[_c('v-text-field',{staticClass:"no-border",attrs:{"solo":"","dense":"","clearable":"","label":"Search...","append-icon":"mdi-magnify","hide-details":"","data-ga":"document_table_search","value":_vm.searchText},on:{"input":_vm.updateSearch}})],1),_c('div',{staticClass:"mb-3 issue-filter-wrapper"},[_c('filter-select',{staticClass:"no-border",attrs:{"items":_vm.types,"label":"Document types","tableFiltering":"documents"},on:{"changeSelection":_vm.changeTypeRows}})],1),(_vm.showUpload)?_c('div',{staticClass:"ml-3 mb-3",attrs:{"id":"upload-dialog"}},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"h100 icon-hover",on:{"click":_vm.showUploadDialog}},'v-icon',attrs,false),on),[_vm._v("mdi-upload")])]}}],null,false,1874214793)},[_c('span',[_vm._v("Upload document")])])],1):_vm._e()]),_c('div',{staticClass:"ml-3 mb-3 column-filter-wrapper"},[_c('v-tooltip',{attrs:{"top":"","open-delay":_vm.popupDelay},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"pointer",attrs:{"id":"cog-icon"}},'v-icon',attrs,false),on),[_vm._v("mdi-cog-outline")])]}}])},[_c('span',[_vm._v("Column filters")])]),_c('CustomMenuFilter',{attrs:{"menuOpen":_vm.colFilterMenuOpen,"menuActivator":"#cog-icon","menuItems":_vm.columnFilterMenuItems,"showAll":_vm.showAllCols,"offset":"y","filterType":"column","table":"documents"},on:{"changeFilters":_vm.toggleColSelection,"clearSelectedItems":_vm.clearSelectedColItems,"setSearchText":_vm.setColumnFilterSearchText,"clearFilterSearch":_vm.clearColumnFilterSearch}})],1)]),_c('div',[_c('DataTable',{attrs:{"className":"no-background clickable-table","itemKey":"id","search":_vm.searchText,"showSelect":false,"headers":_vm.mutableTableHeaders,"table":"documents","items":_vm.selected.length > 0 || !_vm.showAll ? _vm.selected : _vm.tableData,"loading":_vm.dataLoading},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var items = ref.items;
return [_c('tbody',_vm._l((items),function(item){return _c('tr',{key:item.id,on:{"mouseover":function($event){return _vm.selectItem(item)},"mouseleave":function($event){return _vm.unselectItem(item)}}},[(_vm.checkColumn('name'))?_c('td',[_c('span',{staticClass:"doc-name",on:{"click":function($event){return _vm.showViewer(item)}}},[_vm._v(_vm._s(item.name))])]):_vm._e(),(_vm.checkColumn('type'))?_c('td',[_c('div',{staticClass:"type",style:({
                  'background-color': _vm.generateTypeColors(item.type),
                })},[_vm._v(" "+_vm._s(item.type)+" ")])]):_vm._e(),(_vm.checkColumn('date_uploaded'))?_c('td',[_vm._v(" "+_vm._s(item.date_uploaded)+" ")]):_vm._e()])}),0)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }