<template>
  <div>
    <div class="spinner-div" v-if="spreadsheetLoading">
      <v-progress-circular
        :size="45"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
    <div class="spreadsheet-viewer" v-html="html" v-else />
  </div>
</template>

<script>
import { read, utils } from "xlsx-js-style";
import { mapActions, mapState } from "vuex";

export default {
  name: "SpreadsheetViewer",

  props: {
    data: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      html: null,
      tableau: null,
    };
  },
  computed: {
    ...mapState({
      spreadsheetLoading: (state) => state.documents.spreadsheetLoading,
    }),
  },
  methods: {
    ...mapActions({
      updateSpreadsheetLoading: "site/updateSpreadsheetLoading",
    }),
  },
  async mounted() {
    this.updateSpreadsheetLoading(true);
    const data = await fetch(this.data.uri);
    const arrayBuffer = await data.arrayBuffer();
    const workBook = read(arrayBuffer);
    const workSheet = workBook.Sheets[workBook.SheetNames[0]];

    this.html = utils.sheet_to_html(workSheet);
    this.updateSpreadsheetLoading(false);
  },
};
</script>

<style lang="scss">
@import "../assets/scss/_variables";

.spreadsheet-viewer {
  table {
    margin: 16px;
  }
  td {
    outline: 1px solid var(--v-black11-base);
    padding: 2px;
  }
  td:empty {
    padding: 0;
  }
}

.spinner-div {
  position: absolute;
  z-index: 1550;
  top: 12%;
  left: 40%;
}
</style>
