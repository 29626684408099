// PDF pagination and file methods for pdf file viewing
export default {
  async loadPdf(url, pdfjsLib) {
    try {
      // eslint-disable-next-line no-undef
      let loadingTask = pdfjsLib.getDocument(url);
      const pdf = await loadingTask.promise;
      // Return the resolved pdf promise containing pdf file and pagination data
      return pdf;
    } catch (error) {
      console.error("Error loading PDF:", error);
    }
  },
  async loadPdfPage(page, pdfEvent) {
    try {
      const pdfPage = await pdfEvent.getPage(page);
      const scale = 1.5;
      const viewport = pdfPage.getViewport({ scale: scale });
      const outputScale = window.devicePixelRatio || 1;
      const canvasContainer = document.getElementById("canvas_container");
      const canvas = document.getElementById("the-canvas");
      const context = canvas.getContext("2d");
      canvas.width = Math.floor(viewport.width * outputScale);
      canvas.height = Math.floor(viewport.height * outputScale);
      canvas.style.height = "100%";
      canvas.style.maxHeight = "655px";
      canvas.style.width = canvasContainer.offsetWidth + "px";
      const transform =
        outputScale !== 1 ? [outputScale, 0, 0, outputScale, 0, 0] : null;
      const renderContext = {
        canvasContext: context,
        transform: transform,
        viewport: viewport,
      };

      const renderTask = pdfPage.render(renderContext);
      await renderTask.promise;
    } catch (error) {
      console.error("Error getting and rendering PDF page:", error);
    }
  },
  pageUp(totalPages, currentPage) {
    if (totalPages > 1 && currentPage < totalPages) {
      currentPage += 1;
      return currentPage;
    } else if (currentPage === totalPages) {
      currentPage = 1;
      return currentPage;
    }
  },
  pageDown(totalPages, currentPage) {
    if (totalPages > 1 && currentPage > 1) {
      currentPage = currentPage - 1;
      return currentPage;
    } else if (currentPage === 1) {
      currentPage = totalPages;
      return currentPage;
    }
  },
  pageSelection(page, totalPages, currentPage) {
    if (page > 0 && page <= totalPages) {
      currentPage = page;
      return currentPage;
    }
  },
};
