<template>
  <div class="data-table">
    <div
      data-cy="di-data-loading-spinner"
      class="d-flex justify-center align-center data-table__spinner"
      v-if="loading"
    >
      <v-progress-circular
        :size="40"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
    <v-data-table
      data-cy="di-test-table"
      class="no-background di-test-table"
      :search="searchText"
      :items="copiedTestStatusData"
      :headers="mutableTableHeaders"
      emptyDataText="No data available"
      calculate-widths
    >
      <template v-slot:top>
        <div class="d-flex justify-space-between align-center">
          <v-toolbar flat dense class="no-background">
            <div class="d-flex align-center flex-wrap">
              <v-toolbar-title class="mr-2"
                >Data integration status</v-toolbar-title
              >
              <!-- DATA INTEGRATION DESCRIPTION FLYOUT LINK -->
              <v-icon
                data-cy="di-information-icon"
                size="1.25rem"
                class="mr-2 pointer table-description"
                @click="handleHelpClick"
                >{{ informationIcon }}</v-icon
              >
              <div class="mr-4 search-filter-wrapper">
                <v-text-field
                  solo
                  dense
                  label="Search..."
                  append-icon="mdi-magnify"
                  hide-details
                  class="no-border"
                  :value="searchText"
                  @input="updateSearch"
                ></v-text-field>
              </div>
            </div>
          </v-toolbar>
          <div class="column-filter-wrapper mr-4 d-flex">
            <v-tooltip
              top
              :open-delay="popupDelay"
              v-if="isEdit && changesMade"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  data-cy="update-di-table-icon"
                  v-on="on"
                  v-bind="attrs"
                  size="1.7rem"
                  color="green"
                  class="mr-2"
                  @click="updateTable($event)"
                  >mdi-check</v-icon
                >
              </template>
              <span>Save</span>
            </v-tooltip>
            <div class="mr-2 pointer">
              <v-icon
                data-cy="edit-di-table-icon"
                v-if="isInternalAdmin && !isEdit"
                @click="isEdit = true"
                >mdi-pencil</v-icon
              >
              <v-icon
                data-cy="close-edit-di-table-icon"
                size="1.5rem"
                v-if="isEdit"
                @click="closeEditing"
                >mdi-close</v-icon
              >
            </div>
            <v-tooltip top :open-delay="popupDelay">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  id="di-cog-icon"
                  class="pointer"
                  v-on="on"
                  v-bind="attrs"
                  >mdi-cog-outline</v-icon
                >
              </template>
              <span>Column filters</span>
            </v-tooltip>
            <CustomMenuFilter
              :menuOpen="colFilterMenuOpen"
              menuActivator="#di-cog-icon"
              :menuItems="columnFilterMenuItems"
              :showAll="showAllCols"
              offset="y"
              filterType="column"
              table="diTests"
              @changeFilters="toggleColSelection"
              @clearSelectedItems="clearSelectedColItems"
              @setSearchText="setColumnFilterSearchText"
              @clearFilterSearch="clearColumnFilterSearch"
            />
          </div>
        </div>
      </template>
      <template v-slot:body="{ items }">
        <tbody>
          <tr
            v-for="(item, index) in items"
            :key="item.id"
            @mouseover="selectItem(item)"
            @mouseleave="unselectItem(item)"
          >
            <td v-if="checkColumn('name')">
              <div>{{ item.name }}</div>
            </td>
            <td v-if="checkColumn('status')">
              <div class="status-select">
                <v-select
                  data-cy="select-status-input"
                  :value="item.status ? item.status : null"
                  clearable
                  :disabled="!isEdit"
                  class="no-border"
                  :items="item.status_options"
                  item-text="name"
                  :append-icon="isEdit ? '$dropdown' : ''"
                  dense
                  hide-details
                  @change="changeStatusOption($event, item, 'change')"
                  @blur="changeStatusOption($event, item, 'blur')"
                />
              </div>
            </td>
            <td
              v-if="checkColumn('notes')"
              :class="`notes-cell_${index} notes-markdown`"
            >
              <v-menu
                v-if="!isEdit"
                open-on-hover
                offset-y
                top
                content-class="notes-menu"
                nudge-top="16px"
                nudge-left="50px;"
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div v-on="on" v-bind="attrs">
                    <vue-markdown
                      data-cy="di-notes-markdown"
                      :breaks="false"
                      :anchor-attributes="anchorAttrs"
                      >{{ shortenNotes(item.notes) }}</vue-markdown
                    >
                  </div>
                </template>
                <div>
                  <vue-markdown
                    :breaks="false"
                    :anchor-attributes="anchorAttrs"
                    >{{ item.notes }}</vue-markdown
                  >
                </div>
              </v-menu>
              <div v-if="isEdit" class="mb-2 notes-textarea">
                <v-textarea
                  data-cy="di-status-table-notes-input"
                  dense
                  rows="1"
                  auto-grow
                  hide-details
                  clearable
                  v-model="item.notes"
                  @change="changeNotes"
                ></v-textarea>
              </div>
            </td>
          </tr>
        </tbody>
      </template>
      <template v-slot:footer.prepend>
        <div class="last-updated-text">
          <p
            class="ma-0"
            v-if="tableLastUpdated && 'updatedBy' in tableLastUpdated"
          >
            Last updated {{ tableLastUpdated.updatedTs }} by
            {{ tableLastUpdated.updatedBy }}.
          </p>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { popupDelay } from "@/helpers/variables";
import CustomMenuFilter from "@/components/CustomMenuFilter";
import VueMarkdown from "vue-markdown-v2";
import { mapActions } from "vuex";
import { informationIcon } from "@/helpers/variables";

export default {
  name: "DITestTable",
  components: {
    CustomMenuFilter,
    VueMarkdown,
  },
  props: {
    testStatusData: {
      type: Array,
      required: false,
      default: () => [],
    },
    lastUpdated: {
      type: Object,
      required: false,
      default: () => {},
    },
    isInternalAdmin: {
      type: Boolean,
      required: false,
      default: false,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      popupDelay,
      informationIcon,
      selected: [],
      searchText: "",
      colFilterMenuOpen: false,
      selectableTableHeaders: [],
      mutableTableHeaders: [],
      searchColumnMenuText: "",
      showAllCols: false,
      copiedTestStatusData: [],
      isEdit: false,
      changesMade: false,
      anchorAttrs: {
        target: "_blank",
        rel: "noopener noreferrer nofollow",
      },
      properties: ["status", "notes"],
    };
  },
  computed: {
    tableHeaders() {
      const headers = [
        {
          id: 1,
          text: "Initial test",
          value: "name",
          show: true,
          sortable: true,
          width: "28%",
        },
        {
          id: 2,
          text: "Status",
          value: "status",
          show: true,
          sortable: false,
        },
        {
          id: 3,
          text: "Notes",
          value: "notes",
          show: true,
          sortable: false,
          width: "36%",
        },
      ];
      return headers;
    },
    diTestStatusData() {
      if (this.testStatusData && Object.keys(this.testStatusData).length > 0) {
        return this.testStatusData;
      } else {
        return [];
      }
    },
    tableLastUpdated() {
      if (this.lastUpdated && Object.keys(this.lastUpdated).length > 0) {
        if (this.lastUpdated.updatedBy && this.lastUpdated.updatedTs) {
          return this.lastUpdated;
        } else {
          return null;
        }
      }
      return null;
    },
    // Results of filter search if search text present
    columnFilterMenuItems() {
      if (this.searchColumnMenuText) {
        return this.selectableTableHeaders?.filter((header) =>
          header.text
            .toLowerCase()
            .includes(this.searchColumnMenuText?.toLowerCase()),
        );
      } else {
        return this.selectableTableHeaders;
      }
    },
  },
  methods: {
    ...mapActions({
      updateShowBurger: "app/updateShowBurger",
      updateBurgerData: "app/updateBurgerData",
    }),
    handleHelpClick() {
      this.updateShowBurger(true);
      this.updateBurgerData({
        name: "Data integration approach",
        diTableDescription1: `Data integration is a complex process and our analysis is 
          dependent on the quality of data that we receive. To understand a bit more about 
          how WindESCo processes data, please visit our `,
        diTableDescription2: `For a detailed description of data filtering or how data is used 
          in a specific check, please search for the check or reach out to us by commenting on 
          the issue of interest.`,
        diTableKnowledgeLink: "Knowledge Base.",
      });
    },
    updateSearch(e) {
      this.searchText = e;
    },
    selectItem(item) {
      item.selected = true;
    },
    unselectItem(item) {
      item.selected = false;
    },
    checkColumn(column) {
      return this.selectableTableHeaders?.find(
        (c) => c.value === column && c.show,
      );
    },
    toggleColSelection(item) {
      let isAMatch = false;
      const index = this.selectableTableHeaders.indexOf(item);
      if (item === "selectAll") {
        this.selectAllColumns();
      } else if (item === "reset") {
        this.clearColumnFilterSearch();
        this.selectableTableHeaders.forEach((header) => (header.show = true));
        this.mutableTableHeaders = [...this.selectableTableHeaders];
      } else {
        if (index > -1) {
          this.selectableTableHeaders[index].show =
            !this.selectableTableHeaders[index].show;

          if (this.tableHeaders.length > 0) {
            for (const header of this.tableHeaders) {
              if (
                header.id === this.selectableTableHeaders[index].id &&
                !this.selectableTableHeaders[index].show
              ) {
                this.mutableTableHeaders.splice(
                  this.mutableTableHeaders.indexOf(header),
                  1,
                );
                isAMatch = true;
                break;
              }
            }
            // Place or remove the correct column from mutable header array
            if (!isAMatch) {
              let closestIndex = -1; // Initialize index of closest ID
              let minDifference = Infinity; // Initialize minimum difference
              const idOfMissingHeader = this.selectableTableHeaders[index].id;

              if (
                this.mutableTableHeaders.length > 0 &&
                this.mutableTableHeaders[0]?.id < idOfMissingHeader
              ) {
                for (let i = 0; i < this.mutableTableHeaders.length; i++) {
                  const difference =
                    idOfMissingHeader - this.mutableTableHeaders[i].id;
                  if (difference > 0 && difference < minDifference) {
                    closestIndex = i;
                    minDifference = difference;
                  }
                }
                // Insert header just after the header with the closest smaller id
                this.mutableTableHeaders.splice(
                  closestIndex + 1,
                  0,
                  this.selectableTableHeaders[index],
                );
              } else {
                this.mutableTableHeaders.unshift(
                  this.selectableTableHeaders[index],
                );
              }
            }
          }
        }
      }
    },
    selectAllColumns() {
      this.clearColumnFilterSearch();
      this.showAllCols = true;
      for (const header of this.selectableTableHeaders) {
        if (!header.show) {
          this.showAllCols = false;
          break;
        }
      }
      if (this.showAllCols) {
        this.selectableTableHeaders.forEach((header) => (header.show = false));
        this.mutableTableHeaders = [];
      } else {
        this.selectableTableHeaders.forEach((header) => (header.show = true));
        this.mutableTableHeaders = [...this.selectableTableHeaders];
      }
    },
    clearSelectedColItems() {
      this.clearColumnFilterSearch();
      this.selectableTableHeaders.forEach((header) => (header.show = false));
      this.mutableTableHeaders = [];
    },
    clearColumnFilterSearch() {
      this.searchColumnMenuText = "";
    },
    setColumnFilterSearchText(searchText) {
      this.searchColumnMenuText = searchText;
    },
    copyTestStatusData() {
      // eslint-disable-next-line no-undef
      const clone = structuredClone(this.testStatusData);
      return clone;
    },
    changeStatusOption(event, item, action) {
      if (action === "change") {
        item.status = event;
      }
      if (action === "blur") {
        this.changesMade = !this.areArraysEqual(
          this.copiedTestStatusData,
          this.testStatusData,
          this.properties,
        );
      }
    },
    changeNotes() {
      this.changesMade = !this.areArraysEqual(
        this.copiedTestStatusData,
        this.testStatusData,
        this.properties,
      );
    },
    updateTable() {
      this.isEdit = false;
      this.changesMade = false;
      this.$emit("updateDITestStatus", this.copiedTestStatusData);
    },
    shortenNotes(notes) {
      // If there is a new line symbol, split the notes variable into an array of strings
      if (notes && notes.includes("\n")) {
        // Look for quoted code blocks
        const codeBlockRegex = /```[\s\S]*?```/g;
        const codeBlocks = notes.match(codeBlockRegex);
        if (codeBlocks) {
          const firstCodeBlock = codeBlocks[0];
          const lines = firstCodeBlock.split("\n");
          // Truncate first quoted line if there are more than one
          if (lines.length > 3) {
            return "```\n" + lines[1] + "...\n```";
          } else {
            return "```\n" + lines[1] + "\n```";
          }
        }
        const notesArray = notes.split("\n");
        // Return the first element of the notes array with an ellipsis suffix if there were two lines or more
        if (notesArray.length > 1) {
          return notesArray[0] + "...";
        }
        // If there was only one line, return the notes
        return notes;
      }
      // If there is no new line symbol, return the notes variable
      return notes;
    },
    areArraysEqual(array1, array2, properties) {
      if (array1.length !== array2.length) {
        return false;
      }
      for (let i = 0; i < array1.length; i++) {
        const obj1 = array1[i];
        const obj2 = array2[i];
        // Compare values of status and notes in each object
        for (let prop in properties) {
          if (obj1[properties[prop]] !== obj2[properties[prop]]) {
            return false;
          }
        }
      }
      return true;
    },
    closeEditing() {
      this.isEdit = false;
      this.changesMade = false;
      this.copiedTestStatusData = this.copyTestStatusData();
    },
  },
  mounted() {
    this.$emit("callForDITestStatus");
  },
  watch: {
    tableHeaders: {
      immediate: true,
      handler(data) {
        if (data.length > 0) {
          this.selectableTableHeaders = [...data];
          this.mutableTableHeaders = [...data];
        }
      },
    },
    diTestStatusData: {
      immediate: true,
      handler(data) {
        if (data.length > 0) {
          this.copiedTestStatusData = this.copyTestStatusData();
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.data-table {
  position: relative;

  &__spinner {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #000;
    opacity: 0.4;
    z-index: 100;
  }
}

.table-description.mdi.v-icon:hover {
  color: var(--v-primary-base) !important;
}

.status-select {
  max-width: 17rem;
  margin: 0.325rem 0;
}

::v-deep {
  .status-select {
    .v-input--is-disabled .v-input__slot::before {
      border: none;
    }
    .v-input--is-disabled .v-select__selection {
      color: var(--v-disabledText-base);
      font:
        14px "Inter",
        "sans-serif";
    }
  }
}

::v-deep {
  .notes-markdown p,
  ul,
  ol,
  pre {
    margin-bottom: 0;
  }
  .notes-markdown pre {
    font-size: 14px;
  }
}

.notes-menu {
  background-color: var(--v-black10-base);
  padding: 0.5rem 0.75rem 0.5rem 0.75rem;
}

::v-deep {
  .notes-textarea {
    .v-input--is-disabled .v-input__slot::before {
      border: none;
    }
    .v-input--is-disabled .v-text-field__slot {
      font:
        14px "Inter",
        "sans-serif";
      textarea {
        color: var(--v-disabledText-base);
      }
    }
  }
}

.last-updated-text {
  color: grey !important;
}
</style>
<style>
.di-test-table .v-data-table th > span {
  white-space: nowrap;
}
</style>
