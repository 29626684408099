<template>
  <v-dialog
    v-model="docPresent"
    hide-overlay
    scrollable
    v-show="docPresent"
    max-width="70%"
    :width="documentData.type === 'html' ? '70%' : ''"
    ref="downloadDiv"
    :content-class="spreadsheetPresent ? 'spreadsheet-viewer' : ''"
  >
    <div class="doc-dialog-container spinner-anchor">
      <v-toolbar flat color="white" class="toolbar">
        <v-toolbar-title class="viewer-title">
          {{ documentName }}</v-toolbar-title
        >
        <div @click="downloadFile" class="download-container pointer">
          <v-icon size="25px">mdi-file-download</v-icon>
        </div>
        <v-spacer></v-spacer>
        <span @click="handleCloseViewer" class="pointer"
          ><CIcon name="cil-X" size="xl"
        /></span>
      </v-toolbar>
      <div class="divider-div" v-if="pdfPresent">
        <v-divider></v-divider>
      </div>
      <div class="doc-card-content" v-if="pdfPresent">
        <template>
          <div class="pdf-container">
            <slot>Loading...</slot>
          </div>
        </template>
      </div>
      <div v-else-if="spreadsheetPresent">
        <SpreadsheetViewer :data="documentData" />
      </div>
      <div id="display-html" class="html-container" v-else-if="htmlPresent">
        <iframe
          id="html-iframe"
          :srcdoc="documentData.data"
          frameborder="0"
          height="100%"
          width="100%"
        >
        </iframe>
      </div>
      <div class="d-flex justify-center image-container" v-else>
        <div id="display-image">
          <img
            :src="documentData.uri"
            style="max-width: 100%; height: 100%"
            alt="Image"
          />
        </div>
      </div>
      <div class="divider-div" v-if="pdfPresent">
        <v-divider></v-divider>
      </div>
      <v-toolbar flat v-if="pdfPresent">
        <v-spacer></v-spacer>
        <div class="page-control">
          <div @click="pageDown">
            <CIcon name="cil-caret-left" size="sm" class="left-caret" />
          </div>
          <input
            :value="currentPage"
            type="number"
            class="page-input pointer"
            @change="goToPage"
          />
          <div class="page-item">/</div>
          <div class="page-count">{{ totalPages }}</div>
          <div @click="pageUp">
            <CIcon name="cil-caret-right" size="sm" class="right-caret" />
          </div>
        </div>
        <v-spacer></v-spacer>
      </v-toolbar>
    </div>
  </v-dialog>
</template>

<script>
import SpreadsheetViewer from "@/components/SpreadsheetViewer";

export default {
  name: "DocumentViewer",
  emits: ["closeViewer", "pageUp", "pageDown", "goToPage", "print-document"],
  components: {
    SpreadsheetViewer,
  },
  props: {
    documentData: {
      type: Object,
      default: () => {},
    },
    docPresent: {
      type: Boolean,
      default: false,
    },
    totalPages: {
      type: Number,
      default: 0,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      selectedPage: 1,
      downloadDocument: false,
      documentDownload: null,
    };
  },
  computed: {
    pdfPresent() {
      return this.documentData?.type === "pdf";
    },
    spreadsheetPresent() {
      return this.documentData?.type === "xlsx";
    },
    htmlPresent() {
      return this.documentData?.type === "html";
    },
    imagePresent() {
      return Boolean(
        this.documentData?.type === "png" ||
          this.documentData?.type === "svg" ||
          this.documentDatatype === "gif",
      );
    },
    documentName() {
      if (Object.keys(this.documentData).length > 0) {
        return this.documentData.name;
      } else {
        return "";
      }
    },
  },
  methods: {
    handleCloseViewer() {
      this.$emit("closeViewer");
    },
    pageDown() {
      this.$emit("pageDown");
    },
    pageUp() {
      this.$emit("pageUp");
    },
    goToPage(event) {
      if (+event.target.value > this.totalPages || +event.target.value < 1) {
        event.target.value = 1;
        this.$emit("goToPage", 1);
      } else {
        this.$emit("goToPage", +event.target.value);
      }
    },
    downloadFile() {
      let anchorId = document.getElementById("file-download");
      if (anchorId && this.$refs["report-export"].$el) {
        this.$refs["report-export"].$el.removeChild(anchorId);
      }
      if (this.documentData?.uri) {
        let docUrl = document.createElement("a");
        docUrl.href = this.documentData.uri;
        docUrl.id = "file-download";
        docUrl.click();
      }
    },
  },
};
</script>

<style lang="scss">
@import "../assets/scss/_variables";
.doc-dialog-container {
  display: flex;
  flex-direction: column;
}

.doc-card-content {
  width: 100%;
  background: white;
}

.divider-div {
  background: white;
}

.toolbar {
  padding-top: 0.6rem;
}

.download-container {
  display: flex;
  margin-right: 6rem;
  margin-bottom: 0.1rem;
}

.download-container span {
  margin-right: 0.125rem;
  margin-top: 0.4rem;
  font-size: 1rem;
  font-family: "Roboto", "sans-serif";
  color: rgba(50, 50, 50, 1);
}

.download-obj {
  height: 0.1rem;
  width: 0.1rem;
  background: rgba(0, 0, 0, 0);
  border: none;
}

.viewer-title {
  color: $primaryBlue;
  margin-right: 0.17rem;
}

.file-label {
  color: black;
}

.viewer-controls {
  display: flex;
  background: white;
  align-items: center;
  justify-content: space-between;
}

.page-control {
  display: flex;
  min-width: 9rem;
  justify-content: center;
  align-items: center;
}

.pdf-container {
  background: rgba(6, 22, 45, 0.65);
  height: 100%;
  width: 100%;

  .container {
    margin: 0;
    padding: 0;
    width: 100% !important;
  }
}

.v-dialog {
  width: auto;
  overflow-y: unset;
}

.spreadsheet-viewer {
  background-color: #fff;
  overflow-y: auto;
}

.image-container {
  background-color: var(--v-white-base);
}

.html-container {
  height: 700px;
}

.right-caret {
  margin-left: 1.5rem;
}

.right-caret:hover,
.left-caret:hover {
  color: $primaryBlue;
  cursor: pointer;
}

.page-input {
  text-align: right;
  padding-right: 5px;
  /* Firefox remove default arrows*/
  -moz-appearance: textfield;
  width: 2rem;
  margin-top: 0.3rem;
}

.page-item {
  margin-top: 0.3rem;
  padding: 0.5rem;
}

/* Chrome, Safari, Edge, Opera remove default arrows*/
.page-input::-webkit-outer-spin-button,
.page-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.page-count {
  margin-top: 0.3rem;
}

.card-actions {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
}

.html-footer {
  margin-bottom: 2rem;
}

.spinner-anchor {
  position: relative;
}
</style>
