<template>
  <div class="priority-card-container d-flex flex-column justify-space-between">
    <div>
      <div class="orientation-header">
        <p class="mb-0">{{ issueType }}</p>
      </div>
      <div class="mt-2">
        <div class="mb-0 plot-heading">{{ title }}</div>
      </div>
    </div>
    <div class="issue-link">
      <button
        :disabled="title === 'No issue found'"
        :class="{ 'disabled-button': title === 'No issue found' }"
      >
        <template v-if="title !== 'No issue found'">
          <router-link :to="routerLinkTo"> ADDRESS ISSUE </router-link>
        </template>
        <template v-else> ADDRESS ISSUE </template>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "PriorityCard",
  props: {
    issue: {
      type: Object,
      required: false,
      default: null,
    },
    issueType: {
      type: String,
      required: false,
      default: "PRIORITY",
    },
    siteId: {
      type: Number,
      required: false,
      default: null,
    },
    page: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {};
  },
  computed: {
    title() {
      if (this.issue) {
        return this.issue.name;
      }
      return "No issue found";
    },
    issueDefId() {
      if (this.issue) {
        return this.issue.turbine_issue_def_id;
      }
      return null;
    },
    routerLinkTo() {
      const siteId = parseInt(this.$route.params.siteId);
      return {
        name: "Check Detail",
        params: {
          siteId,
          issueDefId: this.issueDefId,
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.priority-card-container {
  border: 1px solid var(--v-border-base);
  border-radius: 8px;
  height: 100%;
  padding: 1rem;

  &__title {
    p {
      font:
        1.35rem "Museo Sans Rounded",
        sans-serif;
      color: var(--v-text-base);
      font-weight: 600;
    }
  }
}

.issue-link {
  display: flex;
  justify-content: flex-end;
  font-size: 0.75rem;
  font-family: "Inter", sans-serif;

  button a {
    border: 1.75px solid var(--v-inverse-base);
    border-radius: 3rem;
    padding: 0.25rem 1rem 0.25rem 1rem;
    background-color: transparent;
    color: var(--v-text-base);
    cursor: pointer;
    text-decoration: none;
  }
  button a:hover {
    background-color: var(--v-hover-base);
    text-decoration: none;
  }
}
.disabled-button {
  border: 1.75px solid var(--v-inverse-base);
  border-radius: 3rem;
  padding: 0.25rem 1rem 0.25rem 1rem;
  background-color: transparent;
  color: var(--v-text-base);
  cursor: not-allowed;
  text-decoration: none;
}
</style>
