<template>
  <article class="metrics-card-container">
    <div
      class="metrics-card"
      :style="
        page === 'newSiteDashboard' ? { backgroundColor: 'transparent' } : {}
      "
    >
      <div
        class="metrics-card__spinner h100"
        v-if="loading"
        data-cy="metrics-card-loader"
      >
        <v-progress-circular
          :size="35"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>
      <div
        class="metrics-card-content"
        v-else-if="!loading && Object.keys(dataStatus).length > 0"
      >
        <h6>
          <div class="d-flex align-center flex-nowrap" data-cy="metrics-card">
            {{ title }}
            <v-tooltip top :open-delay="popupDelay">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  size="1rem"
                  :color="stalenessIndicatorColor"
                  class="ml-1"
                  v-on="on"
                  v-bind="attrs"
                  >{{ stalenessIndicatorIcon }}</v-icon
                >
              </template>
              <span class="tooltip-span"
                ><p class="ma-0">
                  Have a question? Get in touch or schedule a meeting by
                </p>
                <p class="ma-0">
                  following the "Contact us" link at the bottom of the page.
                </p></span
              >
            </v-tooltip>
          </div>
        </h6>
        <div class="metrics-card-body mb-4">
          <div
            v-for="(metric, index) in body"
            :key="index"
            class="metrics-details"
          >
            <p>{{ metric }}</p>
          </div>
        </div>
        <div
          class="signals-link"
          :data-cy="`signals-toggle_${signalsType}`"
          v-if="title !== 'Event data' && isWindescoFlag"
          @click="$emit('toggleSignalsMenu', signalsType, title)"
        >
          <b class="mr-1">Available signals</b>
          <v-icon class="signals-link" small>mdi-arrow-expand-all</v-icon>
        </div>
      </div>
    </div>
  </article>
</template>

<script>
import { mapState } from "vuex";
import { popupDelay } from "@/helpers/variables";

export default {
  name: "MetricsCard",
  props: {
    dataStatus: {
      type: Array,
      required: false,
      default: () => [],
    },
    title: {
      type: String,
      required: true,
    },
    stalenessLevel: {
      type: String,
    },
    body: {
      type: Array,
      required: true,
    },
    availableSignals: {
      type: Array,
      required: false,
      default: () => [],
    },
    isWindescoFlag: {
      type: Boolean,
      required: false,
      default: false,
    },
    page: {
      type: String,
      required: false,
      default: "",
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      popupDelay,
      stalenessTooltip: false,
      newAvailableSignal: "",
    };
  },
  computed: {
    ...mapState({
      userData: (state) => state.user.userData,
    }),
    stalenessIndicatorColor() {
      const themeColors = this.$vuetify.theme.dark
        ? this.$vuetify.theme.themes.dark
        : this.$vuetify.theme.themes.light;
      if (this.stalenessLevel === "internal" && this.userData.is_internal) {
        // yellow for internal users, but not customers
        return themeColors.stalenessYellow + " !important";
      } else if (
        this.stalenessLevel === "external" ||
        this.stalenessLevel === null
      ) {
        return themeColors.error + " !important";
      } else {
        return themeColors.stalenessGreen + " !important";
      }
    },
    stalenessIndicatorIcon() {
      if (
        this.stalenessLevel === "external" ||
        (this.stalenessLevel === "internal" && this.userData.is_internal) ||
        this.stalenessLevel === null
      ) {
        return "mdi-alert-circle";
      } else {
        return "mdi-check-circle";
      }
    },
    signalsType() {
      if (this.title.toLowerCase().includes("high frequency")) {
        return "HF";
      } else if (this.title.toLowerCase().includes("10 minute")) {
        return "10m";
      } else {
        return "event";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.metrics-card-container {
  height: 100%;
}
.metrics-card {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  padding: 1rem;
  height: 100%;

  &__spinner {
    position: absolute;
    top: 38%;
    left: 45%;
    z-index: 100;
  }
}

.metrics-card-content {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  height: 100%;

  h6 {
    margin: 0;
    margin-bottom: 0.65rem;
    font-size: 0.75rem;
    font-family: "Inter", sans-serif;
  }

  p {
    font-size: 0.75rem;
    margin: 0;
  }
}

.signals-link {
  font:
    12px "Inter",
    "sans-serif";
  margin-top: auto;
  .v-icon {
    margin-bottom: 1.5px;
  }
}

.signals-link:hover {
  color: var(--v-availableSignalsHover-base) !important;
  cursor: pointer;
  text-decoration: underline;
  .v-icon {
    color: var(--v-availableSignalsHover-base) !important;
    cursor: pointer;
    text-decoration: none;
  }
}
</style>
