import axios from "@/helpers/axiosAPI";
import store from "@/store";

export async function getDITestStatus(payload) {
  try {
    const siteId = payload.siteId;

    const res = await axios.get(`/sites/${siteId}/data-integration-status`);

    if (res.status === 200) {
      return res.data;
    } else {
      const error = {
        name: "getAvailableSignals",
        message: "Error API call",
        value: "unknown error",
      };
      store.dispatch("error/raiseError", error);
    }
  } catch (error) {
    const err = {
      name: "getAvailableSignals",
      error,
    };
    store.dispatch("error/raiseError", { err });
  }
}

export async function updateDITestStatus(payload) {
  try {
    const siteId = payload.site_id;
    let statusArray = payload.status_table;
    let statusTable = [];
    for (const item of statusArray) {
      const statusObj = {
        name: item.name,
        notes: item.notes,
        status: item.status,
      };
      statusTable.push(statusObj);
    }
    const body = {
      status_table: statusTable,
    };

    const res = await axios.put(
      `/sites/${siteId}/data-integration-status`,
      body,
    );

    if (res.status === 200) {
      return res.data;
    } else {
      const error = {
        name: "updateDITestStatus",
        message: "Error API call",
        value: "unknown error",
      };
      store.dispatch("error/raiseError", error);
    }
  } catch (error) {
    const err = {
      name: "updateDITestStatus",
      error,
    };
    store.dispatch("error/raiseError", { err });
  }
}
