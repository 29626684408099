<template>
  <v-select
    v-model="selected"
    :items="items"
    :menu-props="{ closeOnContentClick: false, offsetY: true }"
    :label="label"
    :append-icon="appendIcon"
    :placeholder="placeholder"
    :item-text="itemText"
    :item-value="itemValue"
    class="no-border"
    multiple
    solo
    dense
    hide-details
    @change="changeSelection"
  >
    <template v-slot:prepend-item>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>
            <div class="d-flex links">
              <b
                class="select-action mr-3 text-decoration-underline"
                :class="{ 'filter-on-color': showAll }"
                @click="selectAllItems()"
              >
                Select all
              </b>
              <b
                class="select-action text-decoration-underline"
                @click="clearItems()"
              >
                Clear
              </b>
            </div>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-select>
</template>

<script>
/**
 * Dynamic display component for a select box
 */
export default {
  name: "FilterSelect",
  emits: ["changeSelection"],
  props: {
    /**
     * The items to be displayed in the select menu
     */
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
    /**
     * The label of the select box
     */
    label: {
      type: String,
      required: true,
      default: "",
    },
    /**
     * The icon appended to the end of the select box indicating to toggle the menu open or closed
     */
    appendIcon: {
      type: String,
      default: "$dropdown",
    },
    /**
     * The text of the item displayed in the select menu
     */
    itemText: {
      type: String,
      default: "text",
    },
    /**
     * The value of the item displayed in the select menu
     */
    itemValue: {
      type: String,
      default: "value",
    },
    placeholder: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      /**
       * Holds a list of selected items from the select menu
       */
      selected: [],
      showAll: false,
    };
  },
  methods: {
    /**
     * Place all select menu options into the `items` array and send to the calling component
     */
    selectAllItems() {
      this.showAll = !this.showAll;
      if (this.showAll) {
        this.selected = this.items;
        this.$emit("changeSelection", this.items);
      } else {
        this.selected = [];
        this.$emit("changeSelection", "none");
      }
    },
    clearItems() {
      this.showAll = false;
      this.selected = [];
      this.$emit("changeSelection", []);
    },
    /**
     * Send an updated list of selected menu items each time a new item is selected
     */
    changeSelection(selectArray) {
      this.$emit("changeSelection", selectArray);
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep {
  .select-action {
    font-size: 0.75rem;
    cursor: pointer;
  }

  .links b:hover {
    color: var(--v-primary-base);
  }

  .filter-on-color {
    color: var(--v-primary-base);
  }

  .v-list-item__action {
    margin-right: 12px !important;
  }

  .v-select__slot input[placeholder="Filter status"],
  .selection-title {
    font-size: 0.875rem;
  }
}
</style>
