<template>
  <div>
    <div class="d-flex flex-wrap align-center justify-space-between">
      <div class="d-flex">
        <div class="mr-3 mb-3 search-filter-wrapper">
          <v-text-field
            solo
            dense
            clearable
            label="Search..."
            append-icon="mdi-magnify"
            hide-details
            class="no-border"
            data-ga="document_table_search"
            :value="searchText"
            @input="updateSearch"
          ></v-text-field>
        </div>
        <div class="mb-3 issue-filter-wrapper">
          <filter-select
            class="no-border"
            :items="types"
            label="Document types"
            @changeSelection="changeTypeRows"
            tableFiltering="documents"
          />
        </div>
        <div id="upload-dialog" class="ml-3 mb-3" v-if="showUpload">
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                @click="showUploadDialog"
                class="h100 icon-hover"
                >mdi-upload</v-icon
              >
            </template>
            <span>Upload document</span>
          </v-tooltip>
        </div>
      </div>

      <div class="ml-3 mb-3 column-filter-wrapper">
        <v-tooltip top :open-delay="popupDelay">
          <template v-slot:activator="{ on, attrs }">
            <v-icon id="cog-icon" class="pointer" v-on="on" v-bind="attrs"
              >mdi-cog-outline</v-icon
            >
          </template>
          <span>Column filters</span>
        </v-tooltip>
        <CustomMenuFilter
          :menuOpen="colFilterMenuOpen"
          menuActivator="#cog-icon"
          :menuItems="columnFilterMenuItems"
          :showAll="showAllCols"
          offset="y"
          filterType="column"
          table="documents"
          @changeFilters="toggleColSelection"
          @clearSelectedItems="clearSelectedColItems"
          @setSearchText="setColumnFilterSearchText"
          @clearFilterSearch="clearColumnFilterSearch"
        />
      </div>
    </div>

    <div>
      <DataTable
        className="no-background clickable-table"
        itemKey="id"
        :search="searchText"
        :showSelect="false"
        :headers="mutableTableHeaders"
        table="documents"
        :items="selected.length > 0 || !showAll ? selected : tableData"
        :loading="dataLoading"
      >
        <template v-slot:body="{ items }">
          <tbody>
            <tr
              v-for="item in items"
              :key="item.id"
              @mouseover="selectItem(item)"
              @mouseleave="unselectItem(item)"
            >
              <td v-if="checkColumn('name')">
                <span @click="showViewer(item)" class="doc-name">{{
                  item.name
                }}</span>
              </td>
              <td v-if="checkColumn('type')">
                <div
                  class="type"
                  :style="{
                    'background-color': generateTypeColors(item.type),
                  }"
                >
                  {{ item.type }}
                </div>
              </td>
              <td v-if="checkColumn('date_uploaded')">
                {{ item.date_uploaded }}
              </td>
            </tr>
          </tbody>
        </template>
      </DataTable>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import dayjs from "dayjs";
import FilterSelect from "./FilterSelect";
import DataTable from "@/components/DataTable";
import { generateTypeColors, popupDelay } from "@/helpers/variables";
import CustomMenuFilter from "./CustomMenuFilter";

export default {
  name: "DocumentTable",
  emits: ["setShowDialog, setShowViewer"],
  props: {
    selectItem: {
      type: Function,
      default: () => {},
    },
    unselectItem: {
      type: Function,
      default: () => {},
    },
    siteLoading: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    "filter-select": FilterSelect,
    DataTable,
    CustomMenuFilter,
  },
  data() {
    return {
      selected: [],
      itemsPerPage: 10,
      searchText: "",
      generateTypeColors,
      popupDelay,
      showAll: true,
      colFilterMenuOpen: false,
      selectableTableHeaders: [],
      mutableTableHeaders: [],
      searchColumnMenuText: "",
      showAllCols: false,
    };
  },
  computed: {
    ...mapState({
      documents: (state) => state.site.documentData,
      loading: (state) => state.site.loading,
      siteData: (state) => state.site.siteData,
    }),
    tableHeaders() {
      const headers = [
        { id: 1, text: "Name", value: "name", align: "center", show: true },
        { id: 2, text: "Type", value: "type", show: true },
        {
          id: 3,
          text: "Date uploaded",
          value: "date_uploaded",
          show: true,
        },
      ];
      return headers;
    },
    /**
     * Map the table data to be displayed
     */
    tableData() {
      if (this.documents?.length > 0) {
        const docMap = this.documents.map((doc) => ({
          id: doc.id,
          name: doc.name,
          type: doc.document_def_name,
          date_uploaded: dayjs(doc.uploaded_ts).format("YYYY-MM-DD"),
          content: doc.document_def_description,
          uri: doc.uri,
        }));

        return docMap;
      } else {
        return [];
      }
    },
    /**
     * Create types array for table filtering by type
     */
    types() {
      let types = [];

      if (this.tableData?.length > 0) {
        this.tableData.forEach((element) => {
          if (!types.includes(element.type)) {
            types.push(element.type);
          }
        });
      }

      return types;
    },
    /**
     * Access check to determine if user can upload a file
     */
    showUpload() {
      if (Object.keys(this.siteData)?.length > 0) {
        return (
          this.siteData.user_has_internal_access &&
          this.siteData.user_has_write_access
        );
      }
      return false;
    },
    dataLoading() {
      return (
        this.siteLoading ||
        this.loading.postDocumentData ||
        this.loading.getDocumentData
      );
    },
    // Results of filter search if search text present
    columnFilterMenuItems() {
      if (this.searchColumnMenuText) {
        return this.selectableTableHeaders?.filter((header) =>
          header.text
            .toLowerCase()
            .includes(this.searchColumnMenuText?.toLowerCase()),
        );
      } else {
        return this.selectableTableHeaders;
      }
    },
  },
  mounted() {
    this.setSearchText();
  },
  watch: {
    tableHeaders: {
      immediate: true,
      handler(data) {
        if (data.length > 0) {
          this.selectableTableHeaders = [...data];
          this.mutableTableHeaders = [...data];
        }
      },
    },
  },
  methods: {
    checkColumn(column) {
      return this.selectableTableHeaders?.find(
        (c) => c.value === column && c.show,
      );
    },
    toggleColSelection(item) {
      let isAMatch = false;
      const index = this.selectableTableHeaders.indexOf(item);
      if (item === "selectAll") {
        this.selectAllColumns();
      } else if (item === "reset") {
        this.clearColumnFilterSearch();
        this.selectableTableHeaders.forEach((header) => (header.show = true));
        this.mutableTableHeaders = [...this.selectableTableHeaders];
      } else {
        if (index > -1) {
          this.selectableTableHeaders[index].show =
            !this.selectableTableHeaders[index].show;

          if (this.tableHeaders.length > 0) {
            for (const header of this.tableHeaders) {
              if (
                header.id === this.selectableTableHeaders[index].id &&
                !this.selectableTableHeaders[index].show
              ) {
                this.mutableTableHeaders.splice(
                  this.mutableTableHeaders.indexOf(header),
                  1,
                );
                isAMatch = true;
                break;
              }
            }
            // Place or remove the correct column from mutable header array
            if (!isAMatch) {
              let closestIndex = -1; // Initialize index of closest ID
              let minDifference = Infinity; // Initialize minimum difference
              const idOfMissingHeader = this.selectableTableHeaders[index].id;

              if (
                this.mutableTableHeaders.length > 0 &&
                this.mutableTableHeaders[0]?.id < idOfMissingHeader
              ) {
                for (let i = 0; i < this.mutableTableHeaders.length; i++) {
                  const difference =
                    idOfMissingHeader - this.mutableTableHeaders[i].id;
                  if (difference > 0 && difference < minDifference) {
                    closestIndex = i;
                    minDifference = difference;
                  }
                }
                // Insert header just after the header with the closest smaller id
                this.mutableTableHeaders.splice(
                  closestIndex + 1,
                  0,
                  this.selectableTableHeaders[index],
                );
              } else {
                this.mutableTableHeaders.unshift(
                  this.selectableTableHeaders[index],
                );
              }
            }
          }
        }
      }
    },
    selectAllColumns() {
      this.clearColumnFilterSearch();
      this.showAllCols = true;
      for (const header of this.selectableTableHeaders) {
        if (!header.show) {
          this.showAllCols = false;
          break;
        }
      }
      if (this.showAllCols) {
        this.selectableTableHeaders.forEach((header) => (header.show = false));
        this.mutableTableHeaders = [];
      } else {
        this.selectableTableHeaders.forEach((header) => (header.show = true));
        this.mutableTableHeaders = [...this.selectableTableHeaders];
      }
    },
    clearSelectedColItems() {
      this.clearColumnFilterSearch();
      this.selectableTableHeaders.forEach((header) => (header.show = false));
      this.mutableTableHeaders = [];
    },
    clearColumnFilterSearch() {
      this.searchColumnMenuText = "";
    },
    setColumnFilterSearchText(searchText) {
      this.searchColumnMenuText = searchText;
    },
    /**
     * Filter table rows by file type
     */
    changeTypeRows(selectedItems) {
      this.showAll = true;
      this.selected = [];

      if (selectedItems === "none") {
        this.showAll = false;
        this.selected = [];
      } else if (selectedItems.length > 0) {
        for (const selection of selectedItems) {
          const temp = this.tableData.filter((item) => selection === item.type);
          this.selected.push(...temp);
        }
        if (this.selected.length === 0) {
          this.showAll = false;
        }
      }
    },
    /**
     * Filter items in the table
     */
    updateSearch(e) {
      const url = new URL(window.location.href);
      this.searchText = e;
      url.searchParams.set("search_for", e);
      if (!this.searchText) {
        url.searchParams.delete("search_for");
      }
      history.replaceState(null, null, url);
    },
    setSearchText() {
      const url = new URL(window.location.href);
      const search = url.searchParams.get("search_for");
      this.updateSearch(search);
    },
    /**
     * Open the dialog for uploading a file
     */
    showUploadDialog() {
      this.$emit("setShowDialog", true);
    },
    showViewer(item) {
      this.$emit("setShowViewer", item);
    },
  },
};
</script>
<style lang="scss" scoped>
.info-icon.popup-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-filter {
  max-width: 18rem;
  width: 18rem;
}

.issue-filter {
  max-width: 9rem;
}

.columns-filter {
  max-width: 8rem;
}

#upload-dialog {
  position: relative;
  z-index: 1000;
}

.upload-spinner {
  position: absolute;
  top: 25%;
  left: 50%;
  z-index: 1070;
}

.type {
  border-radius: 4px;
  padding: 6px 8px;
  margin: 6px 0;
  display: inline-block;
  color: var(--v-docTypeText-base);
}

.upload-container {
  justify-content: space-between;
  flex-wrap: wrap;
}

.doc-name {
  color: var(--v-black1-base);
}
.doc-name:hover {
  text-decoration: underline;
  color: var(--v-primary-base);
}
</style>
