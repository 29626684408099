<template>
  <v-menu
    v-model="openMenu"
    :offset-x="isOffsetX"
    :offset-y="isOffsetY"
    :activator="menuActivator"
    :close-on-content-click="false"
  >
    <v-card>
      <!-- Filter links -->
      <div class="d-flex justify-center pt-3">
        <b
          class="select-action select-all-title text-decoration-underline"
          :class="{ 'filter-on-color': showAll }"
          @click="changeFilters('selectAll')"
        >
          Select all
        </b>
        <b
          class="select-action select-reset-title text-decoration-underline"
          @click="changeFilters('reset')"
        >
          Reset
        </b>
        <b
          class="select-action select-clear-title text-decoration-underline"
          @click="clearSelectedItems()"
        >
          Clear
        </b>
      </div>
      <!-- Search input -->
      <v-text-field
        v-model="menuSearchText"
        label="Search..."
        class="mr-4 ml-4 mt-2 mb-1 no-border lighter-background"
        data-ga="custom_menu_filter_search"
        solo
        dense
        append-icon="mdi-magnify"
        hide-details
        clearable
        @click:clear="clearFilterSearch"
        @input="changeMenuSearchText"
      ></v-text-field>
      <!-- Menu items -->
      <div class="menu-list-wrapper">
        <v-list class="pt-1">
          <template v-for="filter in menuItems">
            <v-list-item
              v-if="filter.text"
              :key="listItemKey(filter)"
              class="pointer"
              @click="changeFilters(filter)"
            >
              <v-list-item-title data-ga="custom_menu_filter_item">{{
                displayText(filter.text)
              }}</v-list-item-title>
              <v-list-item-icon>
                <v-icon color="success" v-if="includesFilter(filter)"
                  >mdi-check</v-icon
                >
              </v-list-item-icon>
            </v-list-item>
          </template>
        </v-list>
      </div>
    </v-card>
  </v-menu>
</template>

<script>
import { gaTrackEvent } from "@/helpers/googleAnalyticsUtility";
import { mapState, mapActions } from "vuex";
import { clickCountLimit } from "@/helpers/variables";

export default {
  name: "CustomMenuFilter",
  props: {
    menuOpen: {
      type: Boolean,
      required: true,
    },
    menuItems: {
      type: Array,
      required: true,
    },
    selectedMenuItems: {
      type: Array,
      required: false,
    },
    showAll: {
      type: Boolean,
      required: false,
      default: false,
    },
    filterType: {
      type: String,
      required: true,
    },
    offset: {
      type: String,
      required: true,
    },
    menuActivator: {
      type: String,
      required: true,
    },
    table: {
      type: String,
      required: false,
      default: "checks",
    },
  },
  components: {},
  data() {
    return {
      openMenu: false,
      checkedMenuItems: [],
      menuSearchText: "",
    };
  },
  computed: {
    ...mapState({
      clickCount: (state) => state.app.clickCount,
    }),
    isOffsetX() {
      return this.offset === "x";
    },
    isOffsetY() {
      return this.offset === "y";
    },
  },
  methods: {
    ...mapActions({
      incrementClickCount: "app/incrementClickCount",
    }),
    changeFilters(filter) {
      let eventLabel = `custom_menu_filter_${this.table}`;
      if (filter === "selectAll" || filter === "reset") {
        this.menuSearchText = "";
        eventLabel = `${eventLabel}_${filter}`;
      } else if (this.table === "issue_table_status") {
        const show = !this.selectedMenuItems.includes(filter.value);
        eventLabel = `${eventLabel}_${filter.value}_${show}`;
      } else {
        let alteredFilterText = filter.text.replaceAll(" ", "_");
        alteredFilterText = alteredFilterText.replaceAll("_(%)", "");
        eventLabel = `${eventLabel}_${alteredFilterText}_${!filter.show}`;
      }

      // Track filter selections
      if (this.clickCount < clickCountLimit) {
        // Data sent to Google Analytics
        eventLabel = eventLabel.toLowerCase();
        if (eventLabel) {
          this.incrementClickCount();
          gaTrackEvent(this.$gtag, {
            eventName: "first_clicks_after_login",
            eventCategory: "user_interaction",
            eventLabel: eventLabel,
            value: this.clickCount,
          });
        }
      }
      this.$emit("changeFilters", filter);
    },
    clearSelectedItems() {
      this.$emit("clearSelectedItems");
      this.menuSearchText = "";
    },
    includesFilter(filter) {
      if (this.filterType === "issue status") {
        return this.selectedMenuItems.includes(filter.value);
      } else if (this.filterType === "column") {
        return filter.show;
      }
    },
    changeMenuSearchText(event) {
      this.$emit("setSearchText", event);
    },
    clearFilterSearch() {
      this.$emit("clearFilterSearch");
    },
    displayText(text) {
      if (this.filterType === "column") {
        if (text.includes("Cumulative gains")) {
          return "Cumulative gains";
        } else if (text.includes("Cumulative loss")) {
          return "Cumulative loss";
        } else if (text.includes("AEP potential")) {
          return "AEP potential";
        } else if (text.includes("AEP gain")) {
          return "AEP gain from fixes";
        } else {
          return text;
        }
      }
      return text;
    },
    listItemKey(filter) {
      if (this.filterType === "issue status") {
        return filter.value;
      } else {
        return filter.id;
      }
    },
  },
  watcher: {
    openMenu: {
      immediate: true,
      handler() {
        this.openMenu = this.menuOpen;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.select-action {
  font-size: 0.85rem;
  cursor: pointer;
}

.select-all-title {
  margin-right: 1.5rem;
}

.select-clear-title {
  margin-left: 1.5rem;
}

.select-all-title:hover,
.select-clear-title:hover,
.select-reset-title:hover {
  color: var(--v-primary-base);
}

::v-deep {
  .no-border.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
    > .v-input__control
    > .v-input__slot {
    padding-right: 5px;
  }
  .lighter-background.v-text-field.v-text-field--enclosed:not(
      .v-text-field--rounded
    )
    > .v-input__control
    > .v-input__slot {
    background-color: var(--v-searchBackground-base);
    max-width: 200px;
  }
}

.menu-list-wrapper {
  max-height: 445px;
  overflow-y: auto;
}

.menu-list-wrapper .v-list-item {
  height: 48px;
}

.menu-list-wrapper .v-list-item .v-list-item__title:hover {
  color: inherit !important;
  text-decoration: none;
}

.menu-list-wrapper .v-icon {
  margin-top: -4px;
}

.filter-on-color {
  color: var(--v-primary-base);
}
</style>
