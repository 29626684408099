<template>
  <v-container v-if="title !== 'Event data'" class="pa-0">
    <!-- Menu -->
    <v-dialog
      :value="isSignalsMenuOpen"
      max-width="600px"
      max-height="450px"
      content-class="dialog"
      hide-overlay
    >
      <v-card>
        <div>
          <div class="d-flex justify-space-between">
            <v-card-title data-cy="signal-list-title"
              >Signals list ({{ signalsType }})</v-card-title
            >
            <v-card-actions>
              <div v-if="isEdit">
                <v-tooltip top :open-delay="popupDelay">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-on="on"
                      v-bind="attrs"
                      :color="changesMade ? 'green' : 'grey'"
                      size="1.5rem"
                      class="mr-2 edit-signals-check"
                      data-cy="update-signal-list"
                      @click="updateSignalsList"
                      >mdi-check</v-icon
                    >
                  </template>
                  <span>Save</span>
                </v-tooltip>
              </div>
              <v-icon
                v-if="isInternal"
                :color="isEdit ? 'primary' : ''"
                size="1.25rem"
                class="mr-2 edit-signals-icon"
                data-cy="edit-signals"
                @click="isEdit = !isEdit"
                >mdi-pencil</v-icon
              >
              <v-icon
                class="pointer close-icon"
                data-cy="close-signal-list"
                @click="closeSignalsList"
                >mdi-close</v-icon
              >
            </v-card-actions>
          </div>
          <v-card-text>
            <div
              data-cy="loading-spinner"
              v-if="loading"
              class="signals-spinner"
            >
              <v-progress-circular
                :size="40"
                color="primary"
                indeterminate
              ></v-progress-circular>
            </div>
            <v-text-field
              dense
              v-model="search"
              label="Search signals..."
              class="mb-3"
              append-icon="mdi-magnify"
            >
            </v-text-field>
            <!-- List of signals -->
            <v-list class="scrollable-list custom-scrollbar">
              <v-list-item v-if="filteredSignals.length === 0"
                ><div data-cy="no-data-msg" class="no-data-text">
                  No data available
                </div></v-list-item
              >
              <v-list-item
                class="pa-0"
                data-cy="list-item"
                v-for="(signal, index) in filteredSignals"
                :key="index"
              >
                <v-list-item-content>
                  <div
                    class="ml-2 add-signal-input signal-input"
                    :data-cy="`signal-name-list-item_${index}`"
                  >
                    <span class="mr-2 monospace-name">{{ signal.name }}</span>
                    <span v-if="signal.label" class="ml-2"
                      >({{ signal.label }})</span
                    >
                  </div>
                </v-list-item-content>
                <v-list-item-action v-if="isEdit">
                  <div>
                    <v-icon
                      class="ml-1"
                      :data-cy="`delete-icon_${index}`"
                      size="1.25rem"
                      @click="deleteSignal(signal)"
                      >mdi-delete</v-icon
                    >
                  </div>
                </v-list-item-action>
              </v-list-item>
            </v-list>
            <v-divider v-if="isInternal && isEdit"></v-divider>
            <!-- Add signal -->
            <div
              v-if="isEdit"
              class="d-flex align-center add-signal-inputs pt-6"
            >
              <div class="ml-2 add-signal-input signal-input">
                <label for="new-signal">Add signal:</label>
                <v-autocomplete
                  name="new-signal"
                  id="new-signal"
                  data-cy="new-signal-def-name"
                  :value="defSelected"
                  :items="filteredSignalDefs"
                  item-text="name"
                  item-value="id"
                  return-object
                  dense
                  @change="changeSignalSelected"
                ></v-autocomplete>
              </div>
              <div class="ml-5 pb-3 add-signal-icon">
                <v-tooltip top :open-delay="popupDelay">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="ml-1"
                      v-on="on"
                      v-bind="attrs"
                      data-cy="add-new-signal-icon"
                      :disabled="isAddSignalDisabled"
                      size="2rem"
                      @click="addNewSignal"
                      >mdi-plus</v-icon
                    >
                  </template>
                  <span>Add selected signal</span>
                </v-tooltip>
              </div>
            </div>
          </v-card-text>
        </div>
      </v-card>
    </v-dialog>
    <div
      v-if="isSignalsMenuOpen"
      class="custom-overlay"
      @click="closeSignalsList"
    ></div>
  </v-container>
</template>

<script>
import { popupDelay } from "@/helpers/variables";

export default {
  name: "SignalsMenu",
  props: {
    title: {
      type: String,
      required: true,
    },
    signals: {
      type: Array,
      required: false,
      default: () => [],
    },
    signalDefs: {
      type: Array,
      required: false,
      default: () => [],
    },
    signalsType: {
      type: String,
      required: false,
      default: "hf",
    },
    isSignalsMenuOpen: {
      type: Boolean,
      required: false,
      default: false,
    },
    isInternal: {
      type: Boolean,
      required: false,
      default: false,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      popupDelay,
      newSignal: {
        name: "",
        id: null,
        description: "",
      },
      search: "",
      copiedSignals: [],
      defSelected: {},
      changesMade: false,
      isEdit: false,
    };
  },
  computed: {
    isAddSignalDisabled() {
      if (!this.newSignal.name) {
        return true;
      }
      return false;
    },
    filteredSignals() {
      return this.copiedSignals.filter((signal) => {
        return signal.name.toLowerCase().includes(this.search.toLowerCase());
      });
    },
    // Write a computed that filters the signalDefs array such that there are no duplicates between the signal array and the signalDef array
    filteredSignalDefs() {
      return this.signalDefs.filter((signalDef) => {
        return !this.copiedSignals.some((signal) => signal.id === signalDef.id);
      });
    },
  },
  methods: {
    copySignals() {
      let copy = [];
      for (const item of this.signals) {
        let obj = { ...item };
        copy.push(obj);
      }
      return copy;
    },
    addNewSignal() {
      if (this.newSignal.label) {
        this.newSignal.name = this.newSignal.label;
      }
      this.copiedSignals.push(this.newSignal);
      this.changesMade = true;
      this.newSignal = {
        name: "",
        id: null,
        description: "",
      };
      this.defSelected = {};
    },
    deleteSignal(signal) {
      for (const item of this.copiedSignals) {
        if (item.name === signal.name) {
          this.copiedSignals.splice(this.copiedSignals.indexOf(item), 1);
          this.changesMade = true;
        }
      }
    },
    updateSignalsList() {
      if (!this.changesMade) {
        return;
      }
      const siteId = +this.$route.params.siteId;
      const type = this.signalsType.toLowerCase();
      const params = {
        site_id: siteId,
        kind: type,
        signals: this.copiedSignals,
      };
      if (this.changesMade) {
        this.$emit("updateSignalsList", params);
        this.changesMade = false;
      }
    },
    closeSignalsList() {
      this.copiedSignals = this.copySignals();
      this.isEdit = false;
      this.changesMade = false;
      this.$emit("closeSignalsMenu");
    },
    changeSignalSelected(event) {
      const kind = this.signalsType.toLowerCase();
      this.newSignal = {
        name: event.name,
        id: event.id,
        description: event.description,
        kind,
      };
    },
  },
  beforeDestroy() {
    this.copiedSignals = [];
  },
  watch: {
    signals: {
      immediate: true,
      handler(data) {
        if (data.length > 0) {
          this.copiedSignals = this.copySignals();
        } else {
          this.copiedSignals = [];
        }
      },
    },
  },
};
</script>
<style scoped lang="scss">
.v-card__actions {
  .v-icon.edit-signals-icon.v-icon--link:hover,
  .v-icon.close-icon.v-icon--link:hover {
    color: var(--v-primary-base) !important;
  }
}

.add-signal-input .v-text-field {
  width: 250px;
}

.signal-input .v-input {
  padding: 0;
}

.v-list-item__action {
  margin-top: 0;
  margin-bottom: 1rem;
}

.v-card {
  min-width: 325px;
  min-height: 300px;
}

.v-card__text {
  position: relative;
}

.signals-spinner {
  position: absolute;
  top: 30%;
  right: 45%;
}

::v-deep {
  .add-signal-icon {
    .theme--dark {
      color: var(--v-addSignalIcon-base) !important;
    }
    .theme--light.v-icon {
      color: var(--v-addSignalIcon-base);
    }
  }
}

.scrollable-list {
  max-height: 350px;
  overflow-y: auto;
  overflow-x: hidden;
}

.no-data-text {
  margin: auto;
}

.custom-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 200;
}

.monospace-name {
  font-family: "JetBrainsMono", monospace;
  background-color: var(--v-mediumGray-base);
  border-radius: 8px;
  padding: 0.125rem 0.375rem;
}
</style>
