<template>
  <div>
    <div class="grid1">
      <div class="grid1-row-1">
        <div class="orientation-header">
          <p class="mb-0">SITE LOCATION</p>
        </div>
        <div class="site-dashboard__header d-flex align-center">
          <h3 class="main-title mr-1">
            <div
              class="d-flex justify-center align-center main-title-spinner"
              v-if="loading.getSite"
            >
              <v-progress-circular
                :size="25"
                color="primary"
                indeterminate
              ></v-progress-circular>
            </div>
            <div v-else>{{ siteName }}</div>
          </h3>
          <div
            class="site-dashboard__header__help d-flex align-center justify-space-between"
            @click="handleHelpClick"
          >
            <v-icon>{{ informationIcon }}</v-icon>
          </div>
          <!-- Download report button -->
          <div id="upload-dialog" class="mr-2 mb-1" v-if="showDownload">
            <v-tooltip top :open-delay="popupDelay">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  @click="downloadReport()"
                  :disabled="exportDisabled"
                  class="h100 icon-hover download-icon-color"
                  data-ga="site_dashboard_export_report"
                  >mdi-download</v-icon
                >
              </template>
              <span>Export report</span>
            </v-tooltip>
            <div class="report-spinner">
              <v-progress-circular
                :size="30"
                color="gray"
                indeterminate
                v-if="reportLoading"
              ></v-progress-circular>
            </div>
          </div>
          <div class="dialog-container">
            <v-menu
              hide-overlay
              v-model="openMenu"
              attach="#upload-dialog"
              absolute
              :position-x="x"
              :position-y="y"
              min-width="300px"
              z-index="25"
              rounded
            >
              <v-card
                dense
                color="green"
                class="success-card d-flex justify-space-between"
              >
                <v-card-text class="white--text">
                  <p>
                    Success! When completed, the report will be emailed to
                    {{ displayEmail }}.
                  </p>
                </v-card-text>
                <v-card-actions>
                  <v-icon class="pointer" color="white"
                    >mdi-close-circle-outline</v-icon
                  >
                </v-card-actions>
              </v-card>
            </v-menu>
          </div>
          <div class="d-flex">
            <div
              class="d-flex justify-center align-center mb-2 component-spinner"
              v-if="!loading.getSite && loadingIssuesByComponent"
            >
              <v-progress-circular
                :size="25"
                color="primary"
                indeterminate
              ></v-progress-circular>
            </div>
            <div class="d-flex" v-else>
              <div
                v-for="system in systems"
                :key="system.name"
                class="mx-1 component-button"
              >
                <v-tooltip top :open-delay="popupDelay">
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                      <router-link
                        :to="{
                          name: 'AllSiteComponents',
                          params: {
                            siteId: siteId,
                            componentName: transformName(
                              system.name,
                              'route',
                              true,
                            ),
                          },
                        }"
                      >
                        <svg
                          viewBox="0 0 28 28"
                          width="28px"
                          height="28px"
                          preserveAspectRatio="xMidYMid meet"
                        >
                          <image
                            width="28px"
                            height="28px"
                            :href="systemIcon(system.name)"
                          />
                        </svg>
                      </router-link>
                    </div>
                  </template>
                  <span>{{
                    `Go to ${transformName(system.name, "display", true)} page`
                  }}</span>
                </v-tooltip>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="grid1-row-2-col-1 site-condition-cards">
        <div class="availability-card plot-figure-container">
          <HealthWidget
            :nCriticalIssues="nOpenReliablilityIssues"
            :name="'SITE RELIABILITY HEALTH'"
          ></HealthWidget>
        </div>
      </div>
      <div class="grid1-row-2-col-2 site-condition-cards">
        <div class="performance-card plot-figure-container">
          <HealthWidget
            :nCriticalIssues="nOpenPerformanceIssues"
            :name="'SITE PERFORMANCE'"
          ></HealthWidget>
        </div>
      </div>
      <div class="grid1-row-2-col-3">
        <div class="ml-1">
          <AtAGlance :page="'site'">
            <template v-slot:header v-if="siteSubscription === 'Swarm'">
              <div class="swarm-nav-btn mb-1">
                <button @click="changeNewLookSwitch">
                  <router-link
                    :to="{
                      name: 'SiteDashboard',
                      params: { siteId: siteId },
                    }"
                  >
                    <div class="d-flex">
                      <div class="site-logo-icon mr-2">
                        <img src="/img/site-logo-minimized.svg" alt="logo" />
                      </div>
                      <div class="swarm-nav-text">GO TO SWARM</div>
                    </div>
                  </router-link>
                </button>
              </div>
            </template>
            <template v-slot:content>
              <div
                class="content-container d-flex flex-column justify-space-between"
              >
                <div class="d-flex justify-space-between align-baseline mt-1">
                  <div class="orientation-header">
                    <p>ALL ISSUES</p>
                  </div>
                  <div
                    class="d-flex flex-row align-baseline ma-0 pa-0 switch-labels"
                  >
                    <label class="mr-3">Cumulative</label>
                    <v-switch
                      inset
                      hide-details
                      dense
                      v-model="lossGainToggle"
                      class="toggle ma-0 pa-0"
                    ></v-switch>
                    <label>Annual</label>
                  </div>
                </div>
                <div class="issues-summary">
                  <div>
                    <p class="content-body error--text">
                      {{ criticalIssuesCount }} critical issues
                    </p>
                  </div>
                  <div class="d-flex justify-space-between align-end mb-2">
                    <p class="at-a-glance-body-footer">
                      <span class="new-issues">{{ newIssuesCount }} new</span
                      ><span class="mr-1">,</span>
                      <span class="in-progress-issues"
                        >{{ inProgressIssuesCount }} in progress</span
                      ><span class="mr-1">,</span>
                      <span class="closed-issues"
                        >{{ closedIssuesCount }} closed</span
                      >
                    </p>
                    <div>
                      <v-btn @click="navigateToIssuesTab" outlined small rounded
                        >ADDRESS ALL</v-btn
                      >
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <template v-slot:footer>
              <div class="mt-2">
                <div class="mb-1">Total opportunity</div>
                <div class="d-flex justify-space-between align-end">
                  <h4 class="mb-0">${{ aepPotentialUsd }}/year</h4>
                  <div :class="impactColor">
                    <div v-if="lossGainToggle">
                      <div>+ ${{ aepGainUsd }}/year</div>
                      <div>{{ aepGainEnergy }} MWh/year</div>
                    </div>
                    <div class="d-flex flex-column" v-else>
                      <div>+ ${{ cumulativeGainUsd }}</div>
                      <div>{{ cumulativeGainEnergy }} MWh</div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </AtAGlance>
        </div>
      </div>
      <div class="grid1-row-3 plot-figure-container">
        <div
          class="d-flex justify-center align-center plot-figure-container__spinner"
          v-if="loadingIssuesOverTimeData"
        >
          <v-progress-circular
            :size="40"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </div>
        <div class="px-4 p-2">
          <div class="d-flex justify-space-between mb-2">
            <h3 class="ma-0 plot-heading">Issues over time</h3>
            <!-- <div class="d-flex align-center mt-1 switch-labels">
                <label class="mr-3 mb-0">Production</label>
                <v-switch
                  inset
                  dense
                  v-model="toggle"
                  disabled
                  hide-details
                  class="toggle ma-0 pa-0"
                  style="transform: scale(0.75)"
                ></v-switch>
                <label class="mb-0 mr-6">Issues</label>
                Comment in when date is determined -->
            <!-- <p class="updated-text ma-0 ">{{ lastUpdatedDate }}</p> -->
            <!-- </div> Comment out until both production and issues plots available -->
          </div>
          <div
            class="figure-container mb-2"
            :style="{ 'background-color': 'transparent' }"
          >
            <Plotly
              v-if="!loadingIssuesOverTimeData"
              :data="issuesOverTimeFigure.data"
              :layout="issuesOverTimeFigure.layout"
              :responsive="true"
              :display-mode-bar="true"
              :showTips="false"
              :use-resize-handler="true"
            ></Plotly>
          </div>
        </div>
      </div>
      <div class="grid1-row-4 mb-2">
        <div class="new-tab">
          <button
            v-for="tab in tabs"
            :key="tab.id"
            :ref="tab.name"
            :class="{ tablinks: true, active: activeTab === tab.id }"
            @click="openTab(tab.id, tab.name, 'click_event')"
          >
            {{ tab.name }}
          </button>
        </div>
        <div class="site-dashboard-tabbed__section">
          <div id="Turbines" v-if="activeTab === 1">
            <SiteDashboardTurbinesTab
              :turbines="turbines"
              :loading="loadingIssuesGroupedByTurbine"
            />
          </div>
          <div id="Systems" class="tabcontent" v-if="activeTab === 2">
            <SiteDashboardSystemsTab
              :systems="systemsSorted"
              :siteId="siteId"
              :loading="loadingIssuesByComponent"
            />
          </div>
          <div id="Issues" class="tabcontent" v-if="activeTab === 3">
            <SiteDashboardIssuesTab
              :issues="mappedIssuesSorted"
              :siteId="siteId"
              :loading="loading.getChecks"
              @toggleUnconfirmed="toggleUnconfirmed"
            />
          </div>
          <div id="Explore" class="tabcontent pa-4" v-if="activeTab === 4">
            <div v-if="!isPowerCurveExplorerOpen && !isEventsViewOpen">
              <NewAnalyticsView
                ref="analyticsView"
                :turbineData="turbinesData"
                :siteId="siteId"
                :pageTitle="'Site dashboard'"
                @setStaticViewRequest="setAnalyticsViewRequest"
                @openPowerCurveExplorer="togglePowerCurveExplorer"
                @openEventsView="toggleEventsView"
              />
            </div>
            <div v-if="!isEventsViewOpen && isPowerCurveExplorerOpen">
              <NewPowerCurveExplorer
                :targetTurbineOptions="targetTurbineOptions"
                :loadingTurbines="loading.getTurbinesBySiteId"
                :siteId="siteId"
                @openPowerCurveExplorer="togglePowerCurveExplorer"
              />
            </div>
            <div v-if="isEventsViewOpen">
              <EventsExplorer
                page="newSiteDashboard"
                @openEventsView="toggleEventsView"
              />
            </div>
          </div>
          <div id="Documents" class="tabcontent" v-show="activeTab === 5">
            <div class="documents-tab">
              <DocumentTable
                :siteLoading="loading.getDocumentData"
                @setShowDialog="toggleUploadDialog"
                @setShowViewer="openDocViewer"
              />
              <UploadDialog
                v-if="showDialog"
                title="Upload site document"
                @setShowDialog="toggleUploadDialog"
                @refreshDocuments="getDocumentData"
              />
              <div
                @click="toggleUploadDialog(false)"
                class="backdrop"
                v-if="showDialog"
              ></div>
              <div class="d-flex success-dialog" v-if="successDialog">
                <p class="success-dialog__text">
                  Success! Your file has been uploaded.
                </p>
              </div>
              <div>
                <div>
                  <div
                    @click="closeViewer"
                    class="backdrop"
                    v-if="showViewer"
                  ></div>
                  <div class="viewer-dialog" v-if="showViewer">
                    <DocumentViewer
                      :documentData="documentObj"
                      :totalPages="pdfTotalPages"
                      :currentPage="pdfCurrentPage"
                      :docPresent="showViewer"
                      :loading="viewerLoading"
                      @closeViewer="closeViewer"
                      @pageUp="setPageUp"
                      @pageDown="setPageDown"
                      @goToPage="selectPage"
                    >
                      <PDFPage v-if="pdfPresent">
                        <div id="canvas_container" class="canvas-container">
                          <canvas id="the-canvas"></canvas>
                        </div>
                      </PDFPage>
                    </DocumentViewer>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            id="Data"
            class="tabcontent"
            v-show="activeTab === 6"
            data-cy="site-v-tab-data"
          >
            <div class="data-tab-grid">
              <div class="site-data-tab" data-cy="uploads-table-wrapper">
                <SiteDataUploadsTable
                  :hasWriteAccess="hasWriteAccess"
                  :loading="loading.getSiteRawdata"
                  :dataUploaderType="dataUploaderType"
                  @setShowDialog="openUploadDialog"
                  @callForSiteDataUploads="callForSiteDataUploads"
                  @updateDataUploaderType="updateDataUploaderType"
                />
                <!-- Dialog for uploading site data -->
                <UploadDialog
                  v-if="openDataUpload"
                  :isSiteDataUpload="openDataUpload"
                  @setShowDialog="openUploadDialog"
                  @postRawSiteData="postRawSiteData"
                />
                <div
                  class="backdrop"
                  v-if="openDataUpload"
                  @click="openDataUpload = false"
                ></div>
                <!-- CRUD table only editable by internal admin users, otherwise, read-only -->
                <div class="data-table mt-5 mb-5">
                  <DITestTable
                    :testStatusData="diTestStatusData"
                    :lastUpdated="diTableLastUpdated"
                    :isInternalAdmin="isInternalAdmin"
                    :loading="diTestDataLoading"
                    @callForDITestStatus="callForDITestStatus"
                    @updateDITestStatus="updateDITestStatus"
                  />
                </div>
              </div>
              <!-- Status cards for data tab -->
              <div class="status-metrics">
                <div
                  v-for="item in dataStatusCards"
                  :key="item.title"
                  class="metrics-cards"
                >
                  <MetricsCard
                    :dataStatus="dataStatusCards"
                    :title="item.title"
                    :stalenessLevel="item.staleness_level"
                    :body="item.body"
                    :isWindescoFlag="userData.email.includes('@windesco')"
                    page="newSiteDashboard"
                    :loading="loading.getDataStatus"
                    @toggleSignalsMenu="toggleSignalsMenu"
                  />
                </div>
                <div v-if="userData.email.includes('@windesco')">
                  <SignalsMenu
                    :isSignalsMenuOpen="isSignalsMenuOpen"
                    :title="signalsMenuTitle"
                    :signals="availableSignals"
                    :signalDefs="signalDefs"
                    :signalsType="signalsMenuType"
                    :isInternal="isInternal"
                    :loading="signalsLoading"
                    @closeSignalsMenu="closeSignalsMenu"
                    @updateSignalsList="updateSignalsList"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="grid2">
      <div class="grid2-row-1-2-col-1">
        <div
          v-if="systemsTabActive"
          class="map-container pa-4 d-flex justify-center align-center"
        >
          <v-img
            width="100%"
            height="100%"
            src="/img/turbine-diagram.png"
            :contain="true"
          ></v-img>
        </div>
        <div v-else class="map-container">
          <MapView
            :mapData="mapConfig"
            height="466px"
            :isMapLoading="loading.getTurbinesBySiteId"
          />
        </div>
      </div>
      <div class="grid2-row-1-col-2">
        <PriorityCard
          :issue="systems.length > 0 ? priorityComponentIssue : null"
          :issueType="'SITE RELIABILITY HEALTH'"
        />
      </div>
      <div class="grid2-row-2-col-2">
        <PriorityCard
          :issue="mappedIssues.length > 0 ? priorityTurbineIssue : null"
          :issueType="'SITE PERFORMANCE'"
        />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/helpers/axiosAPI";
import * as axiosApi from "@/helpers/axiosAPI";
import { mapActions, mapState } from "vuex";
import {
  informationIcon,
  mapTileLayerUrl,
  darkMapTileLayerUrl,
  popupDelay,
} from "@/helpers/variables";
import { roundToString, findFileExt, transformName } from "@/helpers/functions";
import Plotly from "@/components/Plotly";
import AtAGlance from "@/components/SummaryCards/AtAGlance";
import SiteDashboardTurbinesTab from "@/components/NewSiteDashboardComponents/SiteDashboardTurbinesTab";
import SiteDashboardIssuesTab from "@/components/NewSiteDashboardComponents/SiteDashboardIssuesTab";
import NewAnalyticsView from "@/components/NewIssueDetailComponents/NewAnalyticsView";
import NewPowerCurveExplorer from "@/components/NewIssueDetailComponents/NewPowerCurveExplorer";
import EventsExplorer from "@/components/NewSiteDashboardComponents/EventsExplorer";
import SiteDashboardSystemsTab from "@/components/NewSiteDashboardComponents/SiteDashboardSystemsTab";
import DocumentTable from "@/components/DocumentTable";
import UploadDialog from "@/components/UploadDialog";
import DocumentViewer from "@/components/DocumentViewer";
import PDFPagination from "@/helpers/pdfPagination";
import PDFPage from "@/components/PDFPage";
import MapView from "@/components/MapView";
import L from "leaflet";
import HealthWidget from "@/components/SummaryCards/HealthWidget";
import {
  getAvailableSignals,
  getSignalDefinitions,
  updateAvailableSignals,
} from "@/helpers/availableSignalsAPIs";
import { getDITestStatus, updateDITestStatus } from "@/helpers/DITestTableAPIs";
import SiteDataUploadsTable from "@/components/SiteDataUploadsTable";
import DITestTable from "@/components/DITestTable";
import MetricsCard from "@/components/NewSiteDashboardComponents/MetricsCard";
import SignalsMenu from "@/components/SignalsMenu";
import PriorityCard from "@/components/SummaryCards/PriorityCard";
import dayjs from "dayjs";
import {
  setNewLookPreference,
  reversePageMappings,
} from "../services/preferenceService";
import mixpanel from "mixpanel-browser";

export default {
  name: "NewSiteDashboard",
  components: {
    AtAGlance,
    Plotly,
    SiteDashboardTurbinesTab,
    SiteDashboardIssuesTab,
    NewAnalyticsView,
    NewPowerCurveExplorer,
    HealthWidget,
    SiteDashboardSystemsTab,
    DocumentTable,
    UploadDialog,
    DocumentViewer,
    PDFPage,
    SiteDataUploadsTable,
    DITestTable,
    MetricsCard,
    SignalsMenu,
    MapView,
    PriorityCard,
    EventsExplorer,
  },
  data() {
    return {
      informationIcon,
      roundToString,
      popupDelay,
      issuesOverTimeData: null,
      loadingIssuesOverTimeData: false,
      lossGainToggle: true,
      toggle: true,
      tabs: [
        {
          id: 1,
          name: "TURBINES",
        },
        {
          id: 2,
          name: "SYSTEMS",
        },
        {
          id: 3,
          name: "ISSUES",
        },
        {
          id: 4,
          name: "EXPLORE",
        },
        {
          id: 5,
          name: "DOCUMENTS",
        },
        {
          id: 6,
          name: "DATA",
        },
      ],
      activeTab: 1,
      isPowerCurveExplorerOpen: false,
      isEventsViewOpen: false,
      staticAnalyticsViewRequest: {},
      analyticsViewSelection: "yaw_misalignment",
      showDialog: false,
      showViewer: false,
      viewerLoading: false,
      documentObj: {},
      pdfPresent: false,
      pdfTotalPages: 0,
      pdfCurrentPage: 1,
      pdfEvent: {},
      findFileExt,
      isSignalsMenuOpen: false,
      signalsMenuType: "",
      signalsMenuTitle: "",
      signalsLoading: false,
      availableHFSignals: [],
      available10mSignals: [],
      signalDefinitions: [],
      diTestStatusData: [],
      diTableLastUpdated: {},
      diTestDataLoading: false,
      issuesByTurbine: [],
      loadingIssuseGroupedByTurbine: false,
      maintenanceList: [],
      loadingGetMaintenanceData: false,
      issuesByComponent: [],
      loadingIssuesByComponent: false,
      dataUploaderType: "User",
      openDataUpload: false,
      anchors: [
        { x: 435, y: 75, label: "Main bearing" },
        { x: 950, y: 600, label: "Converter" },
      ],
      anchorLines: [{ x1: 470, y1: 320, x2: 470, y2: 100 }],
      displayEmail: "",
      x: 8,
      y: 8,
      reportLoading: false,
      openMenu: false,
      showUnconfirmedOnly: false,
    };
  },
  computed: {
    ...mapState({
      clickCount: (state) => state.app.clickCount,
      userData: (state) => state.user.userData,
      siteData: (state) => state.site.siteData,
      siteSummary: (state) => state.site.siteSummary,
      issuesByType: (state) => state.site.checks,
      loading: (state) => state.site.loading,
      turbinesData: (state) => state.site.turbinesData,
      documents: (state) => state.site.documentData,
      uploadNotification: (state) => state.notifications.uploadNotification,
      siteRawdata: (state) => state.site.siteRawdata,
      showSuccessDialog: (state) => state.site.successDialog,
      statusCards: (state) => state.site.statusCards,
    }),
    siteName() {
      return this.siteData?.name || "Site Name";
    },
    siteId() {
      const siteId = parseInt(this.$route.params.siteId);
      return siteId;
    },
    siteSubscription() {
      return this.siteData?.subscription?.definition?.name;
    },
    issuesOverTimeFigure() {
      if (this.issuesOverTimeData) {
        let issuesOverTimeFigure = this.issuesOverTimeData;
        issuesOverTimeFigure.layout.font = {
          color: this.$vuetify.theme.isDark ? "#fff" : "",
        };
        issuesOverTimeFigure.layout.height = 300;
        issuesOverTimeFigure.layout.margin = { l: 0, b: 0, r: 25, t: 0 };
        issuesOverTimeFigure.layout.autosize = true;
        return issuesOverTimeFigure;
      }
      return {};
    },
    turbines() {
      if (this.issuesByTurbine.length > 0) {
        const turbines = this.issuesByTurbine.map((turbine) => {
          return {
            id: turbine.turbine_id,
            number: turbine.turbine_name,
            status: this.setStatusColor(turbine.n_open),
            openIssues: turbine.n_open ? turbine.n_open : "0",
            openReliabilityIssues: turbine.n_open_reliability
              ? turbine.n_open_reliability
              : "0",
            openPerformanceIssues: turbine.n_open_performance
              ? turbine.n_open_performance
              : "0",
            lastUpdated: turbine.last_updated_ts
              ? dayjs(turbine.last_updated_ts).format("YYYY-MM-DD")
              : "None",
          };
        });
        return turbines;
      }
      return [];
    },
    mappedIssues() {
      let issues = [];
      if (this.issuesByType.length > 0) {
        issues = this.issuesByType.map((issue) => {
          return {
            id: issue.turbine_issue_def_id,
            name: issue.name,
            description: issue.description,
            newIssues: issue.n_new ? issue.n_new : "0",
            ticketsInProgress: issue.work_order_id_to_turbine_issue_id
              ? Object.entries(issue.work_order_id_to_turbine_issue_id).map(
                  ([workOrderId, turbineIssueId]) => ({
                    work_order_id: workOrderId,
                    turbine_issue_id: turbineIssueId,
                  }),
                )
              : [],
            impact_mwh: this.roundToString(issue.aep_loss_mwhpyr_open, 0),
            impact_usd: this.roundToString(issue.aep_loss_upper_usdpyr_open, 0),
            totalIssues: issue.n ? issue.n : "0",
            openIssues: issue.n_open ? issue.n_open : "0",
            unconfirmed: issue.n_unconfirmed ? issue.n_unconfirmed : "0",
            issuesClosed: issue.n_fixed_or_resolved
              ? issue.n_fixed_or_resolved
              : "0",
            lastUpdated: issue.last_updated_ts
              ? dayjs(issue.last_updated_ts).format("YYYY-MM-DD")
              : "None",
          };
        });
      }
      return issues;
    },
    systems() {
      let issues = [];
      if (this.issuesByComponent?.results?.length > 0) {
        issues = this.issuesByComponent.results.map((issue) => {
          return {
            name:
              issue.component_name === "main_bearing"
                ? "rotor_shaft_assembly"
                : issue.component_name,
            newIssues: issue.n_new ? issue.n_new : "0",
            status: this.setStatusColor(issue.n_open),
            ticketsInProgress: issue.work_order_id_to_turbine_issue_id
              ? Object.entries(issue.work_order_id_to_turbine_issue_id).map(
                  ([workOrderId, turbineIssueId]) => ({
                    work_order_id: workOrderId,
                    turbine_issue_id: turbineIssueId,
                  }),
                )
              : [],
            impact_mwh: this.roundToString(issue.aep_loss_mwhpyr_open, 0),
            impact_usd: this.roundToString(issue.aep_loss_upper_usdpyr_open, 0),
            totalIssues: issue.n ? issue.n : "0",
            openIssues: issue.n_open ? issue.n_open : "0",
            issuesClosed: issue.n_fixed_or_resolved
              ? issue.n_fixed_or_resolved
              : "0",
            lastUpdated: issue.last_updated_ts
              ? dayjs(issue.last_updated_ts).format("YYYY-MM-DD")
              : "None",
          };
        });
      }
      return issues;
    },
    issuesContainUnconfirmed() {
      const unconfirmedIssues = this.mappedIssues.filter(
        (issue) => issue.unconfirmed > 0,
      );
      if (unconfirmedIssues.length > 0) {
        return unconfirmedIssues;
      }
      return [];
    },
    mappedIssuesSorted() {
      if (this.showUnconfirmedOnly) {
        if (this.issuesContainUnconfirmed.length > 0) {
          return this.sortIssues("unconfirmed");
        }
      } else if (this.mappedIssues.length > 0) {
        return this.sortIssues("type");
      }
      return [];
    },
    systemsSorted() {
      if (this.systems.length > 0) {
        return this.sortIssues("system");
      }
      return [];
    },
    targetTurbineOptions() {
      if (this.turbinesData.length > 0) {
        const options = this.turbinesData.map((turbine) => ({
          id: turbine.id,
          text: turbine.turbine_name,
          value: turbine.id,
          position: turbine.position,
          site_id: turbine.site_id,
          turbine_model: turbine.turbine_model,
          mapInfoTitle: turbine.mapInfoTitle,
          color: "grey",
        }));
        return options;
      } else {
        return [];
      }
    },
    criticalIssuesCount() {
      const newIssues = this.siteData.issues_summary?.new?.n;
      const inProgressIssues = this.siteData.issues_summary?.in_progress?.n;
      const sum = newIssues + inProgressIssues;
      return sum > 0 ? sum : 0;
    },
    nOpenReliablilityIssues() {
      return this.siteData?.issues_summary?.open_reliability?.n ?? -1;
    },
    nOpenPerformanceIssues() {
      return this.siteData?.issues_summary?.open_performance?.n ?? -1;
    },
    newIssuesCount() {
      const newIssues = this.siteData.issues_summary?.new
        ? this.siteData.issues_summary.new.n
        : 0;
      return newIssues;
    },
    inProgressIssuesCount() {
      const inProgress = this.siteData.issues_summary?.in_progress
        ? this.siteData.issues_summary.in_progress.n
        : 0;
      return inProgress;
    },
    closedIssuesCount() {
      return this.siteData?.issues_summary?.fixed_or_resolved?.n
        ? this.siteData.issues_summary.fixed_or_resolved.n
        : 0;
    },
    aepPotentialUsd() {
      return this.siteSummary.aepPotential.usd;
    },
    aepGainUsd() {
      return this.siteSummary.totalGains.usd;
    },
    aepGainEnergy() {
      return this.siteSummary.totalGains.energy;
    },
    cumulativeGainUsd() {
      return this.siteSummary.cumulativeGain.usd;
    },
    cumulativeGainEnergy() {
      return this.siteSummary.cumulativeGain.energy;
    },
    impactColor() {
      return "gain";
    },
    hasWriteAccess() {
      const siteId = this.$route.params.siteId;
      if (
        this.siteData &&
        "id" in this.siteData &&
        this.siteData.id == siteId &&
        this.siteData.user_has_write_access
      ) {
        return true;
      } else {
        return false;
      }
    },
    isInternalAdmin() {
      if (this.userData.is_internal && this.userData.is_admin) {
        return true;
      }
      return false;
    },
    isInternal() {
      if (this.userData.is_internal) {
        return true;
      }
      return false;
    },
    dataStatusCards() {
      if (this.statusCards?.length > 0) {
        return this.statusCards;
      } else {
        // Returning empty array of cards to maintain cards with spinner while loading data
        return [
          {
            title: "HF data",
            body: [""],
          },
          { title: "10 minute data", body: [""] },
          { title: "Event data", body: [""] },
        ];
      }
    },
    availableSignals() {
      if (
        this.signalsMenuType.toLowerCase() === "hf" &&
        this.availableHFSignals.length > 0
      ) {
        return this.availableHFSignals;
      } else if (
        this.signalsMenuType.toLowerCase() === "10m" &&
        this.available10mSignals.length > 0
      ) {
        return this.available10mSignals;
      } else {
        return [];
      }
    },
    signalDefs() {
      if (this.signalDefinitions.length > 0) {
        return this.signalDefinitions;
      } else {
        return [];
      }
    },
    mapConfig() {
      let mapObj = {
        zoom: 12,
        center: [0, 0],
        url: this.$vuetify.theme.isDark ? darkMapTileLayerUrl : mapTileLayerUrl,
      };
      if (this.turbinesData?.length > 0) {
        // eslint-disable-next-line no-undef
        const clonedTurbines = structuredClone(this.turbinesData);
        mapObj.markers = clonedTurbines;
      }
      return mapObj;
    },
    priorityTurbineIssue() {
      if (this.issuesByType.length > 0) {
        const nonAssetOpenIssues = this.issuesByType.filter(
          (issue) =>
            !(
              issue.description.toLowerCase().includes("main bearing") ||
              issue.description.toLowerCase().includes("converter")
            ) && issue.n_open > 0,
        );
        return nonAssetOpenIssues.length > 0 ? nonAssetOpenIssues[0] : null;
      }
      return null;
    },
    priorityComponentIssue() {
      if (this.issuesByType.length > 0) {
        const assetIssues = this.issuesByType.filter(
          (issue) =>
            (issue.description.toLowerCase().includes("main bearing") ||
              issue.description.toLowerCase().includes("converter")) &&
            issue.n > 0,
        );
        if (
          assetIssues.length > 0 &&
          this.priorityTurbineIssue?.name !== assetIssues[0].name
        ) {
          return assetIssues[0];
        } else {
          return assetIssues[1] ? assetIssues[1] : null;
        }
      }
      return null;
    },
    systemsTabActive() {
      if (this.activeTab === 2) {
        return true;
      }
      return false;
    },
    showDownload() {
      if (Object.keys(this.siteData)?.length > 0) {
        return this.siteData.user_has_write_access;
      } else {
        return false;
      }
    },
    exportDisabled() {
      if (this.reportLoading || this.displayEmail) {
        return true;
      } else {
        return false;
      }
    },
    successDialog() {
      return this.showSuccessDialog;
    },
  },
  methods: {
    ...mapActions({
      getSite: "site/getSite",
      incrementClickCount: "app/incrementClickCount",
      updateShowBurger: "app/updateShowBurger",
      updateBurgerData: "app/updateBurgerData",
      getTurbinesBySiteId: "site/getTurbinesBySiteId",
      handleAxiosError: "error/raiseError",
      getIssuesByType: "site/getChecks",
      getDocuments: "site/getDocumentData",
      setUploadNotification: "notifications/saveUploadNotification",
      postSiteRawdata: "site/postSiteRawdata",
      getSiteRawdata: "site/getSiteRawdata",
      getDataStatus: "site/getDataStatus",
    }),
    transformName,
    handleHelpClick() {
      // Open the flyout containing subscription information
      this.updateShowBurger(true);
      const subName = this.siteData?.subscription?.definition?.name;
      const endDate = this.siteData?.subscription?.end_date;
      const startDate = this.siteData?.subscription?.start_date;
      const description = this.siteData?.subscription?.definition?.description;
      // Send the subscription data to the flyout for display if it exists
      this.updateBurgerData({
        isSubscription: subName ? true : false,
        name: "Subscription: " + subName,
        endDate: endDate ? endDate : "",
        startDate: startDate ? startDate : "",
        description: description ? description : "",
      });
    },
    callInitialAPIs() {
      const siteId = parseInt(this.$route.params.siteId);
      if (
        Object.keys(this.siteData)?.length === 0 ||
        this.siteData?.id !== siteId ||
        (this.siteData?.id === siteId && this.siteData?.issues_summary == null)
      ) {
        this.getSite({
          siteId,
          issuesSummary: 1,
        });
        //this.clearAnalytics();
      }
      if (this.issuesByTurbine.length === 0) {
        this.getIssuesGroupedByTurbine();
      }
      this.getIssuesOverTimeFigure();
      // Calling for documents in initial calls to support notification navigation
      if (
        !this.documents ||
        (this.documents?.length > 0 && this.documents[0]?.id !== siteId) ||
        this.documents?.length === 0
      ) {
        this.getDocumentData();
      }
      this.getTurbinesBySiteId(siteId);
      if (this.issuesByType.length === 0) {
        this.getIssuesByType({ siteId });
      }
      if (this.issuesByComponent.length === 0) {
        this.getIssuesGroupedByComponent();
      }
    },
    openTab(tabId, tabName, method) {
      // Clear the url params, the tabs or methods can populate them
      const url = new URL(window.location.href);
      url.search = "";
      history.replaceState(null, null, url);
      if (method !== "method") {
        mixpanel.track("new_site_dashboard_tab_click", {
          tab_name: tabName,
        });
      }
      // Set the active tab to the clicked tab
      this.activeTab = tabId;
      this.setUrlByTab(tabId);
      if (tabId === 2) {
        this.goToSystemsTab();
      }
      if (tabId === 3) {
        this.goToIssuesTab();
      }
      if (tabId === 4) {
        this.goToExploreTab();
      }
      // Using timeout because next tick is not fast enough to see the element
      if (tabName) {
        this.timeout = setTimeout(() => {
          const element = this.$refs[tabName];
          // Scroll to the active tab
          element[0].scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
          });
        }, 1000);
      }
    },
    isActiveTab(tabId) {
      return this.activeTab === tabId;
    },
    goToIssuesTab() {
      //   if (this.issuesByType.length === 0) {
      this.getIssuesByType({ siteId: this.siteId });
      //   }
    },
    goToExploreTab() {
      if (
        this.siteId &&
        (this.siteId !== this.turbinesData[0]?.site_id ||
          this.turbinesData?.length === 0)
      ) {
        this.getTurbinesBySiteId(this.siteId);
        return;
      }
    },
    goToSystemsTab() {
      //if (this.issuesByComponent.length === 0) {
      this.getIssuesGroupedByComponent();
      //  }
    },
    goToMaintenanceTab() {
      this.getMaintenanceData();
    },
    // Call this when tab is clicked
    setUrlByTab(tabId) {
      const url = new URL(window.location.href);
      const tab = this.tabs.find((tab) => tab.id === tabId);
      if (tab) {
        url.hash = tab.name.toLowerCase();
        window.history.replaceState(null, null, url);
      }
    },
    // Call this on mounted
    setTabByUrl(hashName) {
      const tabName = hashName.replace("#", "").toUpperCase();
      const tab = this.tabs.find((tab) => tab.name === tabName);
      if (tab && tab.id) {
        this.openTab(tab.id, tabName, "method");
      }
    },
    setStatusColor(issues) {
      if (issues === 0) {
        return "green";
      } else if (issues > 0 && issues < 5) {
        return "warning";
      } else {
        return "error";
      }
    },
    setAnalyticsViewRequest(request) {
      // Request details for setting url params on navigation to site analytics tab
      this.staticAnalyticsViewRequest.site_id = request.site_id;
      this.staticAnalyticsViewRequest.start_date = request.start_date;
      this.staticAnalyticsViewRequest.end_date = request.end_date;
      this.staticAnalyticsViewRequest.turbine_ids = request.turbine_ids;
      this.staticAnalyticsViewRequest.view = request.view;
    },
    togglePowerCurveExplorer(isOpen) {
      this.isPowerCurveExplorerOpen = isOpen;
    },
    toggleEventsView(isOpen) {
      this.isEventsViewOpen = isOpen;
    },
    notificationAndDocuments() {
      // TODO:: Redo logic for the way tabs work with the new pages for notification navigation
      if (Object.keys(this.uploadNotification)?.length > 0) {
        const notificationObj = {
          subTab: this.uploadNotification.subTab,
          siteId: this.uploadNotification.siteId,
        };
        if (this.documents?.length > 0) {
          notificationObj.fileId = this.uploadNotification.fileId;
          notificationObj.documents = this.documents;
        }
        return notificationObj;
      } else {
        return null;
      }
    },
    callForSiteDataUploads(refresh) {
      const siteId = +this.$route.params.siteId;
      if (
        this.siteRawdata.length === 0 ||
        this.siteRawdata[0].site_id !== siteId ||
        refresh
      ) {
        const uploaderType = this.dataUploaderType.toLowerCase();
        this.getSiteRawdata({ siteId, uploaderType });
      }
      if (
        this.statusCards.length === 0 ||
        (this.statusCards.length > 0 &&
          this.statusCards[0].site_id !== siteId) ||
        refresh
      ) {
        this.getDataStatus(siteId);
      }
    },
    getDocumentData() {
      if (this.siteId) {
        this.getDocuments(this.siteId);
      }
    },
    toggleUploadDialog(showDialog) {
      this.showDialog = showDialog;
    },
    openUploadDialog(showDataUpload) {
      this.openDataUpload = showDataUpload;
    },
    openDocViewer(item) {
      const fileExt = findFileExt(item.uri);
      const html = document.querySelector("html");
      html.style.overflow = "hidden";
      this.documentObj.name = item.name;
      this.documentObj.type = fileExt;
      this.documentObj.uri = item.uri;

      if (this.documentObj.type === "pdf") {
        this.viewerLoading = true;
        // eslint-disable-next-line no-undef
        this.getPdf(item.uri, pdfjsLib);
      } else if (this.documentObj.type === "html") {
        this.viewerLoading = true;
        axios.get(item.uri).then((response) => {
          this.documentObj.data = response.data;
          this.showViewer = true;
          this.viewerLoading = false;
        });
      } else {
        this.showViewer = true;
        this.viewerLoading = false;
      }
    },
    async getPdf(url, pdfjsLib) {
      const pdf = await PDFPagination.loadPdf(url, pdfjsLib);
      this.pdfTotalPages = pdf.numPages;
      this.pdfEvent = pdf;
      this.getPdfPage(1);
      this.pdfPresent = true;
      this.showViewer = true;
      this.viewerLoading = false;
    },
    // Find a way to make sure this is resolved before calling it again to avoid pages not loading if click through too fast
    async getPdfPage(page) {
      await PDFPagination.loadPdfPage(page, this.pdfEvent);
    },
    async setPageUp() {
      const page = PDFPagination.pageUp(
        this.pdfTotalPages,
        this.pdfCurrentPage,
      );
      this.pdfCurrentPage = page;
      await this.getPdfPage(page);
    },
    async setPageDown() {
      const page = PDFPagination.pageDown(
        this.pdfTotalPages,
        this.pdfCurrentPage,
      );
      this.pdfCurrentPage = page;
      await this.getPdfPage(page);
    },
    async selectPage(page) {
      const pageSelected = PDFPagination.pageSelection(
        page,
        this.pdfTotalPages,
        this.pdfCurrentPage,
      );
      this.pdfCurrentPage = page;
      await this.getPdfPage(pageSelected);
    },
    closeViewer() {
      this.showViewer = false;
      this.pdfPresent = false;
      this.pdfCurrentPage = 1;
      const html = document.querySelector("html");
      html.style.overflow = "auto";
      this.setUploadNotification({});
    },
    downloadReport() {
      this.reportLoading = true;
      axiosApi
        .default({
          url: `/sites/${this.siteData.id}/report`,
          method: "GET",
        })
        .then((response) => {
          if (response.status === 200) {
            this.displayEmail = response.data.email;
            this.openMenu = true;
          }
          setTimeout(() => {
            this.displayEmail = "";
            this.openMenu = false;
          }, 4000);
          this.reportLoading = false;
        })
        .catch((error) => {
          if (error) {
            this.exportError = error;
          }
        });
    },
    closeAlert() {
      this.displayEmail = "";
    },
    async postRawSiteData(payload) {
      await this.postSiteRawdata(payload);
    },
    confirmPageRefresh(event) {
      const rawDataUploading = this.siteRawdata.filter(
        (upload) => upload.uploading,
      );

      if (rawDataUploading.length > 0) {
        event.preventDefault();
        return (event.returnValue = "");
      }
    },
    updateDataUploaderType(value) {
      this.dataUploaderType = value;
      this.callForSiteDataUploads(true);
    },
    async callForDITestStatus() {
      this.diTestDataLoading = true;
      const siteId = +this.$route.params.siteId;
      const response = await getDITestStatus({ siteId });
      this.diTestStatusData = response?.status_table;
      if (response?.updated_ts && response?.updated_user_email) {
        const splitDate = response.updated_ts.split("T");
        const updatedTs = `${splitDate[0]} ${splitDate[1]}`;
        this.diTableLastUpdated = {
          updatedBy: response.updated_user_email,
          updatedTs: updatedTs,
        };
      }
      this.diTestDataLoading = false;
    },
    async updateDITestStatus(payload) {
      this.diTestDataLoading = true;
      const siteId = +this.$route.params.siteId;
      const params = {
        site_id: siteId,
        status_table: payload,
      };
      const response = await updateDITestStatus(params);
      if (response) {
        this.diTestStatusData = response?.status_table;
        const splitDate = response.updated_ts.split("T");
        const updatedTs = `${splitDate[0]} ${splitDate[1]}`;
        this.diTableLastUpdated = {
          updatedBy: response.updated_user_email,
          updatedTs: updatedTs,
        };
      }
      this.diTestDataLoading = false;
    },
    setSignalsType(title) {
      if (title.toLowerCase().includes("high frequency")) {
        return "HF";
      } else {
        return "10m";
      }
    },
    async toggleSignalsMenu(type, title) {
      const siteId = +this.$route.params.siteId;
      this.signalsMenuTitle = title;
      let params = {
        site_id: siteId,
      };
      // Close the dialog if the current title does not match the current kind
      if (title.toLowerCase().includes("high frequency")) {
        if (this.isSignalsMenuOpen && this.signalsMenuType === "10m") {
          this.isSignalsMenuOpen = false;
        }
        this.signalsMenuType = "HF";
        params.kind = "hf";
      } else if (title.includes("10 minute")) {
        if (this.isSignalsMenuOpen && this.signalsMenuType === "HF") {
          this.isSignalsMenuOpen = false;
        }
        this.signalsMenuType = "10m";
        params.kind = "10m";
      }
      // Toggle the menu open or closed
      this.isSignalsMenuOpen = !this.isSignalsMenuOpen;
      // Call for signal definitions
      if (
        this.signalDefinitions.length === 0 ||
        this.signalDefinitions[0]?.kind !== params.kind ||
        this.signalDefinitions.siteId !== siteId
      ) {
        this.signalsLoading = true;
        this.signalDefinitions = await getSignalDefinitions(params);
      }
      // Call for available signals
      if (
        (params.kind === "hf" &&
          (this.availableHFSignals.length === 0 ||
            this.availableHFSignals[0]?.siteId !== siteId)) ||
        (params.kind === "10m" &&
          (this.available10mSignals.length === 0 ||
            this.available10mSignals[0]?.siteId !== siteId))
      ) {
        this.signalsLoading = true;
        if (params.kind === "hf") {
          this.availableHFSignals = await getAvailableSignals(params);
        } else if (params.kind === "10m") {
          this.available10mSignals = await getAvailableSignals(params);
        }
      }
      this.signalsLoading = false;
    },
    async updateSignalsList(payload) {
      this.signalsLoading = true;
      if (payload.kind === "hf") {
        this.availableHFSignals = await updateAvailableSignals(payload);
      } else if (payload.kind === "10m") {
        this.available10mSignals = await updateAvailableSignals(payload);
      }
      this.signalsLoading = false;
    },
    closeSignalsMenu() {
      this.isSignalsMenuOpen = false;
    },
    sortIssues(mode) {
      let sortProperty = "openIssues";
      // Consolidate the sorting into one function to be used by multiple issue groupings
      function sortArrayByCriterion(array, criterion) {
        return array.sort((a, b) => {
          if (a[criterion] > b[criterion]) {
            return -1;
          }
          if (a[criterion] < b[criterion]) {
            return 1;
          }
          return 0;
        });
      }
      // Sort different issue groupings by open issues
      if (mode === "type") {
        return sortArrayByCriterion(this.mappedIssues, sortProperty);
      } else if (mode === "system") {
        return sortArrayByCriterion(this.systems, sortProperty);
      } else if (mode === "unconfirmed") {
        return sortArrayByCriterion(
          this.issuesContainUnconfirmed,
          sortProperty,
        );
      }
    },
    navigateToIssuesTab() {
      if (this.activeTab !== 3) {
        this.openTab(3, "ISSUES", "method");
      }
      this.timeout = setTimeout(() => {
        const element = this.$refs["ISSUES"];
        // Scroll to the active tab
        element[0].scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
      }, 1000);
    },
    systemIcon(componentName) {
      const locations = {
        converter: "/component_icons/converter-icon.svg",
        rotor_shaft_assembly: "/component_icons/main-bearing-icon.svg",
      };
      return locations[componentName] || "";
    },
    changeNewLookSwitch() {
      setNewLookPreference(false);
      const currentRouteName = this.$route.name;
      let targetRouteName;
      this.newLookText = "Preview new look";
      targetRouteName = reversePageMappings[currentRouteName];
      if (targetRouteName) {
        this.$router
          .push({ name: targetRouteName, params: this.$route.params })
          .catch((err) => {
            if (err.name !== "NavigationDuplicated") {
              console.error(err);
            }
          });
      }
    },
    toggleUnconfirmed(showUnconfirmed) {
      this.showUnconfirmedOnly = showUnconfirmed;
    },
    // API calls here
    async getIssuesOverTimeFigure() {
      try {
        this.loadingIssuesOverTimeData = true;
        const siteId = parseInt(this.$route.params.siteId);

        const res = await axios.get(`/sites/${siteId}/plots/issues-over-time`);

        if (res.status === 200) {
          this.issuesOverTimeData = res.data;
        } else {
          this.issuesOverTimeData = null;
          const error = {
            name: "getIssuesOverTimeFigure",
            message: "Error API call",
            value: "unknown error",
          };
          this.handleAxiosError(error);
        }
      } catch (error) {
        this.issuesOverTimeData = null;
        const err = {
          name: "getIssuesOverTimeFigure",
          error,
        };
        this.handleAxiosError(err);
      }
      this.loadingIssuesOverTimeData = false;
    },
    async getIssuesGroupedByTurbine() {
      try {
        this.loadingIssuesGroupedByTurbine = true;
        const params = {
          group_by: "turbine",
          get_losses_gains: true,
        };

        const res = await axios.get(`/sites/${this.siteId}/turbine-issues`, {
          params,
        });

        if (res.status === 200) {
          this.issuesByTurbine = res.data.results;
        } else {
          const error = {
            name: "getIssuesGroupedByTurbine",
            message: "Error API call",
            value: "unknown error",
          };
          this.handleAxiosError(error);
        }
      } catch (error) {
        this.handleAxiosError(error);
      }
      this.loadingIssuesGroupedByTurbine = false;
    },
    async getMaintenanceData() {
      try {
        this.loadingGetMaintenanceData = true;
        const turbineId = parseInt(this.$route.params.turbineId);

        const res = await axios.get(`/turbines/${turbineId}/mods`);

        if (res.status === 200) {
          this.maintenanceList = res.data;
        } else {
          const error = {
            name: "getMaintenanceData",
            message: "Error API call",
            value: "unknown error",
          };
          this.handleAxiosError(error);
        }
      } catch (error) {
        this.handleAxiosError(error);
      }
      this.loadingGetMaintenanceData = false;
    },
    async getIssuesGroupedByComponent() {
      try {
        this.loadingIssuesByComponent = true;
        const params = {
          group_by: "component",
          get_losses_gains: true,
        };
        const res = await axios.get(`/sites/${this.siteId}/turbine-issues`, {
          params,
        });

        if (res.status === 200) {
          this.issuesByComponent = res.data;
        } else {
          const error = {
            name: "getIssuesGroupedByComponent",
            message: "Error API call",
            value: "unknown error",
          };
          this.handleAxiosError(error);
        }
      } catch (error) {
        this.handleAxiosError(error);
      }
      this.loadingIssuesByComponent = false;
    },
  },
  beforeMount() {
    window.addEventListener("beforeunload", this.confirmPageRefresh);
    this.callInitialAPIs();
  },
  async mounted() {
    const url = new URL(window.location.href);
    if (url.hash) {
      this.setTabByUrl(url.hash);
    }

    // Set worker src for pdf retrieval
    // eslint-disable-next-line no-undef
    pdfjsLib.GlobalWorkerOptions.workerSrc =
      "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.14.305/pdf.worker.js";
  },
  beforeDestroy() {
    window.removeEventListener("beforeunload", this.confirmPageRefresh);
  },
  watch: {
    notificationAndDocuments: {
      deep: true,
      handler(value) {
        if (value) {
          const url = new URL(window.location.href);
          const { fileId, documents, subTab } = value;
          if (subTab) {
            url.hash = `#${subTab}`;
            for (const tab of this.dashboardTabs) {
              if (tab.value === subTab) {
                this.subTab = this.dashboardTabs.indexOf(tab);
              }
              if (fileId && documents.length > 0) {
                this.documents.forEach((doc) => {
                  if (doc.id === value.fileId) {
                    this.openDocViewer(doc);
                  }
                });
              }
            }
          }
          this.setUploadNotification({});
          history.replaceState(null, null, url);
        }
      },
    },
    openMenu: {
      handler(value) {
        if (!value) {
          this.displayEmail = "";
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/_variables";
.grid1 {
  display: grid;
  grid-template-columns: 0.65fr 0.65fr 1fr;
  grid-template-rows: 60px 230px auto auto;
  gap: 0.5rem;
}
.grid1-row-1 {
  grid-column: 1 / 4;
}
.grid1-row-2-col-1 {
  grid-column: 1 / 2;
  border-right: 1px solid var(--v-border-base);
  padding: 0.45rem 0.5rem 0.15rem 0;
}
.grid1-row-2-col-2 {
  grid-column: 2 / 3;
  border-right: 1px solid var(--v-border-base);
  padding: 0.45rem 0.5rem 0.15rem 0;
}
.grid1-row-2-col-3 {
  grid-column: 3 / 4;
}
.grid1-row-3 {
  grid-column: 1 / 4;
  border: 1px solid var(--v-border-base);
  border-radius: 8px;
}
.grid1-row-4 {
  grid-column: 1 / 4;
}

.site-dashboard {
  &__header {
    &__help {
      color: var(--v-black3-base);
      box-sizing: border-box;
      padding: 2px 8px 8px 8px;
      cursor: pointer;

      .v-icon {
        color: var(--v-black3-base);
      }
      .v-icon:hover {
        color: var(--v-primary-base) !important;
      }
    }
  }
}

.main-title-spinner {
  min-width: 7rem;
}
.component-spinner {
  min-width: 5rem;
}

.download-icon-color {
  color: var(--v-text-base);
}

.site-condition-cards {
  height: 100%;

  .availability-card {
    height: 100%;
    min-width: 200px;
  }
  .performance-card {
    height: 100%;
    min-width: 200px;
  }
}

.site-logo-icon {
  height: 1rem;
  img {
    width: 100%;
    height: 100%;
  }
}
.swarm-nav-text {
  padding-top: 1px;
}
.swarm-nav-btn {
  font-size: 0.75rem;
  font-family: "Inter", sans-serif;

  button {
    display: flex;
  }
  button a {
    border: 1.75px solid var(--v-inverse-base);
    border-radius: 3rem;
    padding: 0.25rem 0.75rem 0.25rem 0.75rem;
    background-color: transparent;
    color: var(--v-text-base);
    cursor: pointer;
    text-decoration: none;
  }
  button a:hover {
    background-color: var(--v-hover-base);
    text-decoration: none;
  }
}
.disabled-button {
  border: 1.75px solid var(--v-inverse-base);
  border-radius: 3rem;
  padding: 0.25rem 1rem 0.25rem 1rem;
  background-color: transparent;
  color: var(--v-text-base);
  cursor: not-allowed;
  text-decoration: none;
}

.plot-figure-container {
  position: relative;

  &__spinner {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #000;
    opacity: 0.4;
    z-index: 100;
  }
}
.figure-container {
  height: 300px;
  width: 100%;
}

.site-dashboard-tabbed__section {
  border: 1px solid var(--v-border-base);
  border-radius: 8px;
  border-top-left-radius: 0;
  min-height: 160px;
}

.documents-tab {
  position: relative;
  padding: 1rem;
}
.success-dialog {
  position: absolute;
  top: 10%;
  left: 30%;
  width: 21rem;
  height: 3rem;
  font-size: 1.05rem;
  z-index: 2070;
  border-radius: 8px;
  border: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  padding: 0;
  margin: 0;
  overflow: hidden;
  background-color: #1c64ca;
  color: white;
  align-items: center;
  justify-content: center;
}
.success-dialog__text {
  margin: 0;
}
.canvas-container {
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  justify-content: center;
}
.viewer-dialog {
  position: relative;
  z-index: 1500;
  width: 100%;
}

#Data {
  padding: 1rem;
}
.data-tab-grid {
  display: grid;
  grid-template-columns: 1fr 375px;
  grid-template-rows: 1fr;
  gap: 1rem;
}
.site-data-tab {
  position: relative;
}
.status-metrics {
  display: flex;
  flex-direction: column;
}
.metrics-cards {
  margin-bottom: 1rem;
  min-height: 200px;
}

.grid2 {
  display: grid;
  grid-template-columns: 1fr 300px;
  grid-template-rows: 230px 230px;
  gap: 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}
.grid2-row-1-2-col-1 {
  grid-row: 1 / 3;
  grid-column: 1 / 2;
}
.grid2-row-1-col-2 {
  grid-row: 1 / 2;
  grid-column: 2 / 3;
}
.grid2-row-2-col-2 {
  grid-row: 2 / 3;
  grid-column: 2 / 3;
}

.content-container {
  position: relative;
  height: 110px;
  p {
    margin-bottom: 0;
    margin-top: 0;
  }
}
.content-body {
  font:
    1.75rem Inter,
    sans-serif;
  font-weight: 500;
  text-transform: capitalize;
}
.content-footer {
  font:
    0.85rem Roboto,
    sans-serif;
  font-weight: 600;

  .new-issues {
    color: var(--v-newIssues-base);
  }
  .in-progress-issues {
    color: var(--v-inProgressIssues-base);
  }
  .closed-issues {
    color: var(--v-closedIssues-base);
  }
  color: var(--v-black5-base);
}

.gain {
  color: $green;
}
.loss {
  color: $red;
}

.map-container {
  height: 100%;
  width: 100%;
  border: 1px solid var(--v-border-base);
  border-radius: 8px;
  overflow: hidden;
}

@media (max-width: 1550px) {
  .data-tab-grid {
    display: block;
  }
  .status-metrics {
    display: flex;
    flex-direction: row;
    padding-top: 0.5rem;
  }
  .metrics-cards {
    min-height: 200px;
    margin-right: 1.5rem;
    width: 300px;
  }
}

@media (max-width: 1264px) {
  .grid1 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 60px 245px auto auto auto;
    gap: 0.5rem;
  }
  .grid1-row-1 {
    grid-column: 1 / 3;
  }
  .grid1-row-2-col-1 {
    grid-column: 1 / 2;
    border-right: none;
    padding-right: 0;
    padding-bottom: 0;
    margin-left: 0.75rem;
  }
  .grid1-row-2-col-2 {
    grid-column: 2 / 3;
    border-right: none;
    border-left: 1px solid var(--v-border-base);
    padding-right: 0;
    padding-bottom: 0;
    padding-left: 0.5rem;
  }
  .grid1-row-2-col-3 {
    grid-column: 1 / 3;
  }
  .grid1-row-3 {
    grid-column: 1 / 3;
    border: 1px solid var(--v-border-base);
    border-radius: 8px;
    margin-top: 0.25rem;
  }
  .grid1-row-4 {
    grid-column: 1 / 4;
  }

  .grid2 {
    margin-top: 0;
  }
}
</style>
