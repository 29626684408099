<template>
  <div class="data-table">
    <div
      class="d-flex justify-center align-center data-table__spinner"
      v-if="dataLoading"
    >
      <v-progress-circular
        :size="40"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
    <v-data-table
      :search="cSearchText"
      :class="className"
      :show-select="showSelect"
      :item-key="itemKey"
      :headers="headers"
      :items="items"
      :items-per-page="cItemsPerPage"
      :page="cPage"
      :sort-by="cSortBy"
      :sort-desc="cSortDesc"
      :options.sync="pageOptions"
      :hide-default-footer="hideFooter"
      :item-class="itemClass"
      :no-data-text="emptyTableText"
      @update:page="updatePaginationUrl($event, 'page')"
      @update:sort-desc="updatePaginationUrl($event, 'sort_desc')"
      @update:items-per-page="updatePaginationUrl($event, 'items_per_page')"
    >
      <!-- Pass on all named slots -->
      <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot" />

      <template
        v-for="slot in Object.keys($scopedSlots)"
        :slot="slot"
        slot-scope="scope"
      >
        <!-- Pass on all scoped slots -->
        <slot :name="slot" v-bind="scope" />
      </template>
      <template v-slot:item.description="{ item }">
        <vue-markdown :key="item.name" :breaks="false">{{
          item.description
        }}</vue-markdown>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import VueMarkdown from "vue-markdown-v2";
import { mapState, mapActions } from "vuex";
import { clickCountLimit } from "@/helpers/variables";
import { gaTrackEvent } from "@/helpers/googleAnalyticsUtility";

/**
 * Dynamic table component which displays props data
 */
export default {
  name: "DataTable",
  components: {
    VueMarkdown,
  },
  emits: ["getData"],
  props: {
    /**
     * Determines if table is loading or not, if loading, show a spinner animation
     */
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    /**
     * Determines if table has a background or not
     */
    className: {
      type: String,
      default: "no-background",
    },
    /**
     * Determines whether to show a checkbox for all rows in the table or not
     */
    showSelect: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Array,
      required: false,
      default: () => [],
    },
    /**
     * Unique key for each table row
     */
    itemKey: {
      type: [String, Number],
      required: true,
    },
    /**
     * The column names of the table
     */
    headers: {
      type: Array,
      required: true,
    },
    /**
     * The data to be displayed in the rows of the table
     */
    items: {
      type: undefined,
      required: true,
    },
    /**
     * Used for pagination
     *
     * Currently, an empty string is passed from all sources
     */
    searchKey: {
      type: String,
      default: "",
    },
    /**
     * Determines how many rows are visible in the table at a time
     */
    itemsPerPage: {
      type: Number,
      default: 10,
    },
    page: {
      type: Number,
      default: 1,
    },
    /**
     * Header value to sort by
     */
    sortBy: {
      type: String,
      required: false,
      default: undefined,
    },
    sortDesc: {
      type: Boolean,
      required: false,
      default: undefined,
    },
    /**
     * The value by which to search the table data
     */
    search: String,
    hideFooter: {
      type: Boolean,
      required: false,
      default: false,
    },
    itemClass: {
      type: Function,
      required: false,
      default: null,
    },
    emptyDataText: {
      type: String,
    },
    isCheckIssuesTable: {
      type: Boolean,
      required: false,
      default: false,
    },
    isSwarm: {
      type: Boolean,
      required: false,
    },
    table: {
      type: String,
      required: false,
      default: "checks",
    },
  },
  data() {
    return {
      /**
       * Pagination param that sets the starting page from which to display data
       */
      offset: 0,
      /**
       * Holds the value of the column by which to sort the table
       */
      sortColumnName: "",
      /**
       * Sort the table data by this value
       */
      sortOrder: "ASC",
      /**
       * Holds loading value for pagination selection
       *
       * True when page is being changed, false otherwise
       */
      initialLoaded: false,
      /**
       * Timer used to pause if pagination params change and table data needs to be retrieved again
       */
      timer: null,
      /**
       * Attributes of markdown element `<vue-markdown>`
       */
      anchorAttrs: {
        target: "_blank",
        rel: "noopener noreferrer nofollow",
      },
      pageOptions: {},
      searchText: "",
      url: {},
      initialSort: false,
    };
  },
  computed: {
    ...mapState({
      isInternal: (state) => state.user.userData.is_internal,
      clickCount: (state) => state.app.clickCount,
    }),
    emptyTableText() {
      if (this.emptyDataText) {
        return this.emptyDataText;
      } else {
        return "No data yet available";
      }
    },
    cItemsPerPage() {
      return this.pageOptions.itemsPerPage || this.itemsPerPage;
    },
    cPage() {
      return this.pageOptions.page || this.page;
    },
    cSortBy() {
      return this.pageOptions.sortBy || this.sortBy;
    },
    cSortDesc() {
      return this.pageOptions.sortDesc || this.sortDesc;
    },
    cSearchText() {
      return this.searchText || this.search;
    },
    dataLoading() {
      return this.loading;
    },
  },
  methods: {
    ...mapActions({
      incrementClickCount: "app/incrementClickCount",
    }),
    setInitialUrl(mode) {
      const url = new URL(window.location.href);
      this.searchText = this.search;
      const sortBy = url.searchParams.get("sort_by");
      if (!this.hideFooter) {
        if (url.searchParams.toString()) {
          // Make sure items per page is set to default if empty
          if (!url.searchParams.has("items_per_page")) {
            url.searchParams.set("items_per_page", this.itemsPerPage);
          }
          this.pageOptions.itemsPerPage =
            +url.searchParams?.get("items_per_page");
          this.pageOptions.page = +url.searchParams?.get("page");
          // Make sure the sort by array only has one item
          if (this.pageOptions.sortBy.length > 0) {
            this.pageOptions.sortBy = [];
          }
          if (sortBy && sortBy !== "undefined" && sortBy !== null) {
            this.pageOptions.sortBy.push(url.searchParams.get("sort_by"));
          } else {
            url.searchParams.delete("sort_by");
          }
          if (url.search.includes("sort_desc")) {
            this.pageOptions.sortDesc = [
              +url.searchParams?.get("sort_desc") ? true : false,
            ];
          }
          if (mode === "activated") {
            this.searchText = url.searchParams.get("search_for");
          }
          this.$emit("setIssueFilters");
        } else {
          url.searchParams.set("page", this.page);
          url.searchParams.set("sort_desc", this.sortDesc ? 1 : 0);
          this.pageOptions.itemsPerPage = this.itemsPerPage;
          this.pageOptions.page = this.page;
          if (this.pageOptions.sortBy.length > 0) {
            this.pageOptions.sortBy = [];
          }
          if (this.sortBy) {
            if (url.hash === "#data" && this.sortBy !== "upload_start") {
              this.pageOptions.sortBy.push("upload_start");
              url.searchParams.set("sort_by", "upload_start");
              url.searchParams.set("items_per_page", 5); // TODO: Hardcoded
            } else {
              this.pageOptions.sortBy.push(this.sortBy);
              url.searchParams.set("sort_by", this.sortBy);
              url.searchParams.set("items_per_page", this.itemsPerPage);
            }
          }
          this.pageOptions.sortDesc = [this.sortDesc];
        }
        history.replaceState(null, null, url);
      }
    },
    /**
     * Clears and creates a timeout to pause while retrieving a new page set of data
     */
    fetchEntriesDebounced(callback) {
      clearTimeout(this.timer);

      this.timer = setTimeout(() => {
        callback();
      }, 500);
    },
    updatePaginationUrl(value, pageOption) {
      const url = new URL(window.location.href);
      if (
        pageOption === "sort_desc" &&
        value.length > 0 &&
        value[0] !== +url.searchParams?.get(pageOption)
      ) {
        if (this.pageOptions.sortBy.length > 0) {
          url.searchParams.set("sort_by", this.pageOptions.sortBy[0]);
        }
        url.searchParams.set("sort_desc", value[0] ? 1 : 0);

        if (this.initialSort) {
          this.initialSort = false;
        } else {
          // Data sent to Google Analytics
          const eventLabel = `${this.table}_table_column_sort_${
            this.pageOptions.sortBy
          }_${value[0] ? "desc" : "asc"}`;
          if (this.clickCount < clickCountLimit) {
            this.incrementClickCount();
            gaTrackEvent(this.$gtag, {
              eventName: "first_clicks_after_login",
              eventCategory: "user_interaction",
              eventLabel: eventLabel,
              value: this.clickCount,
            });
          }
        }
      } else if (value !== +url.searchParams?.get(pageOption)) {
        url.searchParams.set(pageOption, value);
      }

      history.replaceState(null, null, url);
    },
  },
  mounted() {
    const url = new URL(window.location.href);
    if (
      !this.isSwarm &&
      !url.hash.includes("swarm") &&
      url.hash !== "#site_analytics"
    ) {
      this.setInitialUrl("initial");
    }
    this.initialSort = true;
  },
  activated() {
    const url = new URL(window.location.href);
    if (
      !this.isSwarm &&
      !url.hash.includes("swarm") &&
      url.hash !== "#site_analytics"
    ) {
      this.setInitialUrl("activated");
    }
    this.initialSort = true;
  },
  watch: {
    searchKey: {
      handler(value) {
        if (value) {
          this.fetchEntriesDebounced(this.getTableData);
        }
      },
      immediate: true,
    },
    search: {
      handler(value) {
        const url = new URL(window.location.href);
        const searchFor = url.searchParams?.get("search_for");
        if (value && searchFor && value !== searchFor) {
          setTimeout(() => {
            this.searchText = value;
            url.searchParams.set("search_for", value);
            history.replaceState(null, null, url);
          }, 500);
        }
      },
    },
    items: {
      handler(data) {
        const url = new URL(window.location.href);
        const page = Number(url.searchParams.get("page"));
        if (data?.length > 0 && !isNaN(page)) {
          this.pageOptions.page = page;
        }
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.data-table {
  position: relative;

  &__spinner {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #000;
    opacity: 0.4;
    z-index: 1000;
  }
}
</style>
<style>
thead.v-data-table-header {
  white-space: nowrap;
}
</style>
